import * as c from './c'
import axios from 'axios'

const defConfig = {
    headers:{
        'Content-Type': 'application/json'
    }
}
const fileConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
}
function folderIdGen() {
    const x = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let y = ''
    for (let i = 0; i < 10; i++) {
        const k = Math.floor(Math.random() * x.length)
        y += x[k]
    }
    return y
}
function lang(){
    return localStorage.getItem('lang')
}
function getPlugins() {
    return Array.from(navigator.plugins).map(plugin => plugin.name);
}
const userAgent = navigator.userAgent
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const deviceID = `${userAgent}-${getPlugins().join(',')}-${timezone}`
/* ------ Login System ------ */

export const login = (email,password) => async(dispatch) =>{
    dispatch({
        type:c.c1
    })
    try{
        const {data} = await axios.post(c.SERVER_URL+'login',{email,password,deviceID},defConfig)
        dispatch({
            type:c.c2,
            token:data.token,
            id:data._id
        })
    }
    catch(e){
        dispatch({
            type:c.c3,
            error:e.response.data.message
        })
    }    
}
export const createUser = (userInfo, mode) => async (dispatch) => {
    dispatch({
        type: c.c5
    });
    const modes = ['email',false,'password','phone',false]
    const modesC = [c.c6,c.c6_,c.c6__,c.c6___,c.c6____]
    try {
        if(modes[mode]){
            await axios.post(c.SERVER_URL + 'createUser', {...userInfo, lang:lang()}, {
                headers: {
                    'Content-Type': 'application/json',
                    'mode': modes[mode]
                }
            });
            dispatch({
                type: modesC[mode],
                email: userInfo.email ? userInfo.email : '',
                phoneNumber :userInfo.phoneNumber ? userInfo.phoneNumber : '',
            });
            return true
        }else{
            await axios.post(c.SERVER_URL + 'verifyCode', userInfo, defConfig)
            dispatch({
                type: modesC[mode],
            });
            return true
        }
    } catch (e) {
        dispatch({
            type: c.c7,
            error: e.response.data.message
        });
        return false
    }
}
export const logout = ()=>async(dispatch,getState)=>{
    const userID = getState().login.id
    localStorage.setItem('userID',userID)
    dispatch({type:c.c4})
    dispatch({type:c.c8})
    try{
        await axios.post(c.SERVER_URL+'logout',{userID:localStorage.getItem('userID'),deviceID},defConfig)
    }catch(e){}
}
export const getUserDetails = () => async (dispatch,getState) =>{
    dispatch({
        type:c.c8
    })
    const token = getState().login.token
    const id = getState().login.id
    if(token){
        const config = {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        try {
            const {data} = await axios.post(c.SERVER_URL+'getUserDetails',{id},config)
            dispatch({
                type:c.c9,
                payload:data,
            })
        } catch (e) {
            dispatch({
                type:c.c4
            })
            dispatch({
                type:c.c10,
                error:e.response.data.message,
            })
        }
    }else{
        dispatch({
            type:c.c4
        })
        dispatch({
            type:c.c10,
            error:'An error has occurred'
        })
    }
}
export const getUserBuyRequests = () => async (dispatch,getState) =>{
    dispatch({
        type:c.c41
    })
    const id = getState().login.id
    try {
        const {data} = await axios.post(c.SERVER_URL+'getBuyRequests',{userId:id},defConfig)
        dispatch({
            type:c.c42,
            payload:data,
        })
    } catch (e) {
        dispatch({
            type:c.c43
        })
    }
}

/* -------- Courses -------- */

export const getCourses = () => async(dispatch) =>{
    dispatch({
        type:c.c11
    })
    try{
        const {data} = await axios.get(c.SERVER_URL+'getCourses',defConfig)
        dispatch({
            type:c.c12,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c13,
            error:e.response.data.message
        })
    }    
}
export const getCourse = (id) => async(dispatch) =>{
    dispatch({
        type:c.c14
    })
    try{
        const {data} = await axios.post(c.SERVER_URL+'getCourse',{id},defConfig)
        dispatch({
            type:c.c15,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c16,
            error:e.response.data.message
        })
    }    
}
export const getUserCourses = () => async (dispatch,getState)=>{
    const id = getState().login.id
    dispatch({
        type:c.c17
    })
    try{
        const {data:data1} = await axios.post(c.SERVER_URL+'getUserCourses',{id},defConfig)
        const {data:data2} = await axios.post(c.SERVER_URL+'getUserFavCourses',{id},defConfig)
        dispatch({
            type:c.c18,
            uC:data1,
            fC:data2,
        })
    }
    catch(e){
        dispatch({
            type:c.c19,
            error:e.response.data.message
        })
    }    
}
export const addUserFavCourse = async(userId,courseId,history,location) =>{
    try{
        await axios.post(c.SERVER_URL+'addUserFavCourse',{userId,courseId},defConfig)
        history(location.pathname + '?mssg=done')
    }catch(e){
        history(location.pathname + '?mssg=already_exists')
    }
}
export const deleteUserFavCourse = async(userId,courseId,history,location) =>{
    try{
        await axios.delete(c.SERVER_URL+'deleteUserFavCourse',{data:{userId,courseId}},defConfig)
        history(location.pathname + '?mssg=done1')
        window.location.reload()
    }catch(e){
        history(location.pathname + '?mssg=notdone')
        window.location.reload()
    }
}
export const getCourseVideos = (id,title1,duration1,id1,x,videourl,n) => async(dispatch)=>{
    dispatch({type:c.c23})
    try{
        const {data} = await axios.post(c.SERVER_URL+'getVideos',{course:id},defConfig)
        dispatch({
            type:c.c24,
            payload:data
        })
        title1(data[n].title)
        id1(data[n]._id)
        duration1(x(data[n].duration))
        videourl(data[n].filename)
    }catch(e){
        dispatch({
            type:c.c25,
            error:e.response.data.message
        })
    }
}
export const getCourse1stVideo = (id,title1,duration1,x,y) => async(dispatch)=>{
    dispatch({type:c.c23_})
    try{
        const {data:data1} = await axios.post(c.SERVER_URL+'getCourse1stVideo',{course:id},defConfig)
        const {data:data2} = await axios.post(c.SERVER_URL+'getVideos1',{course:id},defConfig)
        dispatch({
            type:c.c24_,
            payload:data1
        })
        title1(data1.title)
        duration1(x(data1.duration))
        y(data2)
    }catch(e){
        dispatch({
            type:c.c25_,
            error:e.response.data.message
        })
    }
}
export const buyRequest = (form) => async(dispatch)=>{
    dispatch({type:c.c38})
    try{
        await axios.post(c.SERVER_URL+'buyCourse',{...form,lang:lang()},defConfig)
        dispatch({
            type:c.c39,
            success:true,
        })
        return true
    }catch(e){
        dispatch({
            type:c.c40,
            error:e.response.data.message
        })
        throw Error(e.response.data.message)
    }
}

/* -------- User Settings -------- */

export const changePassword = (info,history)=>async(dispatch)=>{
    dispatch({type:c.c20})
    try{
        const {data} = await axios.post(c.SERVER_URL+'changePassword',info,defConfig)
        dispatch({
            type:c.c21,
            payload:data
        })
        history('/user?mssg=done2')
    }catch(e){
        dispatch({
            type:c.c22,
            error:e.response.data.message
        })
    }
}
export async function uploadPhoto(profilePicture,userId){
    try{
        const base64Data = profilePicture.split(',')[1]
        const byteCharacters = atob(base64Data)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const formData = new FormData()
        formData.append('image', blob, folderIdGen()+'.png')
        formData.append('userId', userId)
        await axios.post(c.SERVER_URL+'profileChange', formData, fileConfig)
        window.location.replace('/user?mssg=done3')
    }catch(e){
        window.location.replace('/user?mssg=error1')
    }
}
export const deleteUserCourse = (userId,courseId) => async(dispatch,getState)=>{
    const token = getState().login.token
    try{
        await axios.delete(c.SERVER_URL+'deleteUserCourse',{
            data:{userId,courseId},
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        window.location.replace('/admin?mssg=done')
    }catch(e){
        console.log(e)
        window.location.replace('/admin?mssg=error')
    }
}
export const postComment = (form)=>async(dispatch)=>{
    dispatch({
        type:c.c26
    })
    try{
        await axios.post(c.SERVER_URL+'postComment',form,defConfig)
        dispatch({
            type:c.c27,
            success:true
        })
    }
    catch(e){
        dispatch({
            type:c.c28,
            error:e.response.data.message
        })
    }  
}
export const getComment = (courseId)=>async(dispatch)=>{
    dispatch({
        type:c.c29
    })
    try{
        const {data} = await axios.post(c.SERVER_URL+'getComments',{courseId},defConfig)
        dispatch({
            type:c.c30,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c31,
            error:e.response.data.message
        })
    }  
}
export const getCourseProgress = (courseId,userId)=>async(dispatch)=>{
    dispatch({
        type:c.c32
    })
    try{
        const {data} = await axios.post(c.SERVER_URL+'getCourseProgress',{courseId,userId},defConfig)
        dispatch({
            type:c.c33,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c34,
            error:e.response.data.message
        })
    }  
}
export const videoDone = async(form)=>{
    await axios.post(c.SERVER_URL+'courseProgress',form,defConfig)
}
/* -------- Admin -------- */

export const getUsers = ()=>async(dispatch,getState)=>{
    const token = getState().login.token
    dispatch({
        type:c.c35
    })
    try{
        const {data} = await axios.get(c.SERVER_URL+'getUsers',{
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        dispatch({
            type:c.c36,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c37,
            error:e.response.data.message
        })
    }  
}
export const downloadExcel = ()=> async(dispatch,getState)=>{
    const token = getState().login.token
    try{
        const response = await axios.get(c.SERVER_URL+'getExcel',{
            headers:{
                'Authorization':'Bearer '+token
            },
            responseType:'blob'
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'data.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
    }catch(e){
        console.log(e.response.data)
        window.location.replace('/admin?mssg=error')
    }
}
export const confirmReq = (form)=>async(dispatch,getState)=>{
    const token = getState().login.token
    try{
        await axios.post(c.SERVER_URL+'confirmBuyRequest',form,{
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        window.location.replace('/admin?mssg=done')
    }catch(e){
        window.location.replace('/admin?mssg=error')
    }
}
export const confirmReqNoUser = (form)=>async(dispatch,getState)=>{
    const token = getState().login.token
    try{
        await axios.post(c.SERVER_URL+'confirmBuyRequestNoUser',form,{
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        window.location.replace('/admin?mssg=done')
    }catch(e){
        window.location.replace('/admin?mssg=error')
    }
}
export const addUserCourse = (userId,courseId) => async(dispatch,getState)=>{
    const token = getState().login.token
    try{
        await axios.post(c.SERVER_URL+'addUserCourse',{userId,courseId},{
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        window.location.replace('/admin?mssg=done')
    }catch(e){
        window.location.replace('/admin?mssg=error')
    }
}
export const getUsersBuyRequests = ()=>async(dispatch,getState)=>{
    const token = getState().login.token
    dispatch({
        type:c.c44
    })
    try{
        const {data} = await axios.get(c.SERVER_URL+'getBuyRequestsAdmin',{
            headers:{
                'Authorization':'Bearer '+token
            }
        })
        dispatch({
            type:c.c45,
            payload:data
        })
    }
    catch(e){
        dispatch({
            type:c.c46,
            error:e.response.data.message
        })
    }  
}