import { useEffect } from 'react'
import { Route, Routes} from 'react-router-dom'
import { Footer, Header, WhatsAppBTN } from './components'
import AOS from 'aos'
import * as p from './pages'
import './style/reset.css'
import './style/style.css'
import './style/aos.css'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
export default function App() {
	if(localStorage.getItem('lang') === null){
        localStorage.setItem('lang','fr')
	}
	useEffect(() => {
		AOS.init({
			delay: 200,
			once: false,
			easing: 'ease-in-out',
		})
	  }, [])
    return (
		<>
			<WhatsAppBTN />
			<Header />
				<Routes>
					<Route exact path='' element={<p.Home/>} />
					<Route exact path='/courses' element={<p.Courses/>} />
					<Route exact path='/contact' element={<p.Contact/>} />
					<Route exact path='/admin' element={<p.Admin/>} />
					<Route exact path='/signin' element={<p.SignIn/>} />
					<Route exact path='/signup' element={<p.SignUp/>} />
					<Route exact path='/user' element={<p.User/>} />
					<Route exact path='/view-course/:id' element={<p.Preview/>} />
					<Route exact path='/course/:id' element={<p.Course/>} />
					<Route exact path='/change-password/' element={<p.ChangePassword/>} />
					<Route exact path='/buy-course/:id' element={<p.BuyCourse/>} />
					<Route exact path='/course-bought-success' element={<p.BuySuccess/>} />
					<Route exact path='/data-validation-in-excel/:id' element={<p.ExcelCourseDataValidation/>} />
					<Route exact path='/master-excel/:id' element={<p.ExcelCourse/>} />
					<Route exact path='/pivot-tables-in-excel-and-graphs/:id' element={<p.ExcelGraphsTCD/>} />
					<Route path='*' element={<p.PageNotFound />} />
				</Routes>
			<Footer />
		</>	
    )
}