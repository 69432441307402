import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './translation'
import { BrowserRouter as Router} from 'react-router-dom'
import store from './backend/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ChakraProvider,extendTheme } from '@chakra-ui/react'
import { PrimeReactProvider } from 'primereact/api'
var persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root'))
const theme = extendTheme({
	styles: {
	  global: {
		"a": {
			padding:0,
		},
	  },
	},
	colors:{
		color1:'#5586FF',
	},
	components: {
		Radio: {
			baseStyle: {
				control: {
					_checked: {
					bg: 'color1',
					borderColor: 'color1'
	},},},},},
})
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<Router>
				<ChakraProvider theme={theme}>
					<PrimeReactProvider>
						<App />
					</PrimeReactProvider>
				</ChakraProvider>
			</Router>
		</PersistGate>
	</Provider>
)