import * as c from './c'
export function login(state={},action){
    switch(action.type) {
        case c.c1:
            return {loading:true, user:undefined}
        case c.c2:
            return {loading:false, success:true, token:action.token,id:action.id}
        case c.c3:
            return {loading:false, error:action.error}
        case c.c4:
            return {loading:false, user:undefined}
        default:
            return state
    }
}
export function signup(state={},action){
    switch(action.type) {
        case c.c5:
            return {loading:true, user:undefined}
        case c.c5_:
            return {loading:false, user:undefined}
        case c.c6:
            return {loading:false, email:action.email}
        case c.c6_:
            return {loading:false, email:action.email, emailVerified:true}
        case c.c6__:
            return {loading:false, email:action.email, passwordSaved:true}
        case c.c6___:
            return {loading:false, email:action.email, phoneNumber:action.phoneNumber}
        case c.c6____:
            return {loading:false, email:action.email, phoneVerified:true}
        case c.c7:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function userDetails(state={},action){
    switch(action.type) {
        case c.c8:
            return {loading:true}
        case c.c9:
            return {loading:false, userInfo:action.payload}
        case c.c10:
            return {loading:false, error:action.error}
        default:
            return state
    }
}

export function courses(state={},action){
    switch(action.type) {
        case c.c11:
            return {loading:true}
        case c.c12:
            return {loading:false, courses:action.payload}
        case c.c13:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function course(state={},action){
    switch(action.type) {
        case c.c14:
            return {loading:true}
        case c.c15:
            return {loading:false, course:action.payload}
        case c.c16:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function userCourses(state={},action){
    switch(action.type) {
        case c.c17:
            return {loading:true}
        case c.c18:
            return {loading:false, uC:action.uC,fC:action.fC}
        case c.c19:
            return {loading:false, error:action.error}
        default:
            return state
    }
}

export function changePassword(state={},action){
    switch(action.type) {
        case c.c20:
            return {loading:true}
        case c.c21:
            return {loading:false, message:action.payload}
        case c.c22:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function courseVideos(state={},action){
    switch(action.type) {
        case c.c23:
            return {loading:true}
        case c.c24:
            return {loading:false, videos:action.payload}
        case c.c25:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function course1stVideo(state={},action){
    switch(action.type) {
        case c.c23_:
            return {loading:true}
        case c.c24_:
            return {loading:false, video:action.payload}
        case c.c25_:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function postComment(state={},action){
    switch(action.type) {
        case c.c26:
            return {loading:true}
        case c.c27:
            return {loading:false, success:true}
        case c.c28:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function getComments(state={},action){
    switch(action.type) {
        case c.c29:
            return {loading:true}
        case c.c30:
            return {loading:false, comments:action.payload}
        case c.c31:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function getCourseProgress(state={},action){
    switch(action.type) {
        case c.c32:
            return {loading:true}
        case c.c33:
            return {loading:false, courseProgress:action.payload}
        case c.c34:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
// Admin
export function getUsers(state={},action){
    switch(action.type) {
        case c.c35:
            return {loading:true}
        case c.c36:
            return {...state,loading:false, users:action.payload}
        case c.c37:
            return {loading:false, error:action.error}
        default:
            return state
    }
}

export function buyRequest(state={},action){
    switch(action.type) {
        case c.c38:
            return {loading:true}
        case c.c39:
            return {loading:false, success:true}
        case c.c40:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function getUserBuyRequests(state={},action){
    switch(action.type) {
        case c.c41:
            return {loading:true}
        case c.c42:
            return {loading:false, requests:action.payload}
        case c.c43:
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export function getUsersBuyRequests(state={},action){
    switch(action.type) {
        case c.c44:
            return {loading:true}
        case c.c45:
            return {loading:false, requests:action.payload}
        case c.c46:
            return {loading:false, error:action.error}
        default:
            return state
    }
}