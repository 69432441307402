
import * as imgs from '../img'
export default function VideoList({ title, duration, done,click,focus }) {
    return (
        <div className={'videoList n '+(focus ? focus : '')+(done ? done : '')} onClick={click}>
            {done?<img src={imgs.icon6} alt='' />:<img src={imgs.icon5} alt='' />}
            <h2>{title}</h2>
            <p>{duration}</p>
        </div>
    )
}