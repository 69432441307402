import Rating from './Rating'

export default function Comment({pfp,username,rating,body}) {
    return (
        <div className='ratingComment'>
            <div className='userCred'>
                <div className='profile'>
                    <img src={'/profilePhotos/'+pfp} alt='' />
                    <h4>{username}</h4>
                </div>
                <div className='rating'>
                    <Rating n={rating} />
                </div>
            </div>
            <div className='comment'>
                <p>{body}</p>
            </div>
        </div>
    )
}
