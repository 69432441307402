import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseCard, Loading } from '../components'
import { getCourses } from '../backend/actions'
import { useDispatch, useSelector } from 'react-redux'
import { checkForTitleTranslation } from '.'
export default function Courses() {
    const dispatch = useDispatch()
    const { loading, courses } = useSelector(state => state.courses)
    const { t } = useTranslation()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
        dispatch(getCourses())
        // eslint-disable-next-line
    }, [dispatch]);
    if (loading || !courses) {
        return <Loading />
    }
    return (
        <>
            <div style={{ height: 80 }} />
            <div className='topBanner1'>
                <h1>{t('t47')}</h1>
            </div>
            <div className='coursesRow'>
                {courses.map(course => <CourseCard
                    key={course.id}
                    thumb={`/coursesFolder/${course.folderId}/thumb.jpg`}
                    title={checkForTitleTranslation(course.title,t)}
                    rating={course.rating}
                    id={course.id}
                    customURL={course.customURL}
                />)}
            </div>
        </>
    )
}
