import { useTranslation } from "react-i18next"

export default function Loading() {
    const {t} = useTranslation()
    return <div className='loading n'>
        <div style={{ height: 100 }} />
        <div className='loadingio-spinner-dual-ball-ttmb4i7p5jm'><div className='ldio-sbr194yombs'>
            <div></div><div></div><div></div>
        </div></div>
        <h1>{t('t116')}</h1>
    </div>
}