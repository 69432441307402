import { useState, useEffect } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom'
import { Dropdown, Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Icon } from '.'
import * as imgs from '../img'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../backend/actions'
function toggleMode(darkTheme) {
    document.documentElement.style.setProperty('--bg', darkTheme ? '#001' : '#fff');
    document.documentElement.style.setProperty('--fg', darkTheme ? '#fff' : '#000');
    document.documentElement.style.setProperty('--color4', darkTheme ? '#5586FFAA' : '#0005');
    document.documentElement.style.setProperty('--color5', darkTheme ? '#eee5' : '#eee');
    document.documentElement.style.setProperty('--filter1', darkTheme ? 'none' : 'invert()');
    localStorage.setItem('theme', darkTheme ? 'dark' : 'light')
}
export default function Header() {
    const { userInfo } = useSelector(state => state.userDetails)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation().pathname
    const [menu, setMenu] = useState(false);
    const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
    const history = useNavigate()
    function logout(){
        history('/')
        setTimeout(() => {
            dispatch(a.logout())
        }, 200)
    }
    function changeLanguage(e) {
        i18n.changeLanguage(e.key)
        localStorage.setItem('lang', e.key)
    }
    function splitter(str) {
        if (str) {
            return str.split(' ')[0]
        }
        return ''
    }
    const menuBarStyle = {
        right: menu ? 0 : '-100%',
        boxShadow: menu ? '-10px 20px 20px #0009' : 'none',
    }
    useEffect(() => {
        toggleMode(localStorage.getItem('theme') === 'dark')
        i18n.changeLanguage(localStorage.getItem('lang'))
    }, [i18n]);
    const items = [
        {
            key: 'en',
            label: <NavLink className='lang'>
                <img src={imgs.p1} alt='' />
                {t('t4')}
            </NavLink>,
        },
        {
            key: 'fr',
            label: <NavLink className='lang'>
                <img src={imgs.p2} alt='' />
                {t('t5')}
            </NavLink>,
        },
    ];
    return (
        <nav className='second'>
            <div className='logo' onClick={() => { history('/') }}>
                <img src={darkMode ? imgs.logoW : imgs.logoB} alt='' />
            </div>
            <div className='headings'>
                <ul>
                    <NavLink className={location === '' ? 'n linkHead active' : 'n linkHead'} to=''>{t('t1')}</NavLink>
                    <NavLink className={location === 'courses' ? 'n linkHead active' : 'n linkHead'} to='/courses'>{t('t2')}</NavLink>
                    <NavLink className={location === 'contact' ? 'n linkHead active' : 'n linkHead'} to='/contact'>{t('t3')}</NavLink>
                </ul>
            </div>
            <div className='tools'>
                {userInfo ? <NavLink className='buttonLink' to='/user' style={{ marginRight: 10 }}>{userInfo.fullName && t('t89') + splitter(userInfo.fullName) + '!'}</NavLink> : <>
                    <NavLink className='buttonLink' to='/signup'>{t('t9')}</NavLink>
                    <NavLink className='buttonLink' to='/signin'>{t('t8')}</NavLink>
                </>}
                <Dropdown
                    menu={{
                        items,
                        selectable: true,
                        defaultSelectedKeys: [localStorage.getItem('lang')],
                        onClick: changeLanguage,
                    }}
                >
                    <Button>
                        <Space>
                            <Icon type='globe' />
                        </Space>
                    </Button>
                </Dropdown>
                <div className='lightDarkSwitch' onClick={() => { setDarkMode(!darkMode); toggleMode(!darkMode) }}>
                    <div className={!darkMode ? 'active' : null}>
                        <Icon type='sun' />
                    </div>
                    <div className={darkMode ? 'active' : null}>
                        <Icon type='moon' />
                    </div>
                </div>
            </div>
            <div className='menuToggler' onClick={() => { setMenu(!menu) }}>
                {!menu && <Icon type='bars' />}
                {menu && <Icon type='xmark' />}
            </div>
            <div className='menuBar' style={menuBarStyle}>
                <div className='logo'>
                    <img src={darkMode ? imgs.logoW : imgs.logoB} alt='' onClick={() => { history('/'); setMenu(false) }} />
                </div>
                <div className='links'>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/'>{t('t1')}</NavLink>
                    </div>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/courses'>{t('t2')}</NavLink>
                    </div>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/contact'>{t('t3')}</NavLink>
                    </div>
                    <div className='linkRow expanded focused'>
                    {userInfo ? <>
                        <NavLink onClick={() => { setMenu(false) }} to='/user' style={{ marginRight: 10 }}>{userInfo.fullName && t('t89') + splitter(userInfo.fullName) + '!'}</NavLink>
                        <NavLink className='logout' onClick={() => { setMenu(false);logout() }} style={{ marginRight: 10 }}>{t('t90')}</NavLink>
                        </> : <>
                        <NavLink className='not' onClick={() => { setMenu(false) }} to='/signin'>{t('t8')}</NavLink>
                        <NavLink onClick={() => { setMenu(false) }} to='/signup'>{t('t9')}</NavLink>
                    </>}
                    </div>
                </div>
                <div className='toolsX'>
                    <div className='lightDarkSwitch' onClick={() => { setDarkMode(!darkMode); toggleMode(!darkMode) }}>
                        <div className={!darkMode ? 'active' : null}>
                            <Icon type='sun' />
                        </div>
                        <div className={darkMode ? 'active' : null}>
                            <Icon type='moon' />
                        </div>
                    </div>
                    <div className='lang' onClick={() => { setMenu(false); changeLanguage({ key: 'en' }) }}>
                        <img src={imgs.p1} alt='' />
                        <h2>{t('t4')}</h2>
                    </div>
                    <div className='lang' onClick={() => { setMenu(false); changeLanguage({ key: 'fr' }) }}>
                        <img src={imgs.p2} alt='' />
                        <h2>{t('t5')}</h2>
                    </div>
                </div>
            </div>
        </nav>
    )
}
