import { ErrorMessage } from 'formik';
import { Input, Icon } from '.';
export default function InputDiv({ placeholder, name, icon, type }) {
    return (
        <div className='inputDiv'>
            <Input icon={icon} inputDescription={placeholder} name={name} type={type} />
            <div className='error'>
                <ErrorMessage name={name} >
                    {msg => <>
                        <Icon type='xmark' />
                        <p>{msg}</p>
                    </>}
                </ErrorMessage>
            </div>
        </div>
    )
}
