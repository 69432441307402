export {default as Home} from './home.jsx'
export {default as Admin} from './admin.jsx'
export {default as Contact} from './contact.jsx'
export {default as Courses} from './courses.jsx'
export { SignIn, SignUp, ChangePassword} from './login.jsx'
export {default as Preview} from './view-course.jsx'
export {default as Course} from './course.jsx'
export {default as User} from './user.jsx'
export {default as PageNotFound} from './PageNotFound.jsx'
export {default as BuyCourse, BuySuccess} from './buy-course.jsx'
export {ExcelCourse,ExcelCourseDataValidation,ExcelGraphsTCD} from './landings.jsx'

export function checkForTitleTranslation(title,t){
    const titlesDic = {
        masterExcelUnleashPower:t('t161'),
        masterExcelUnleashPowerDesc:t('t162'),
        dataValidation:t('t216'),
        dataValidationDescription:t('t217'),
        tcd_graphs:t('t246'),
        tcd_graphs_desc:t('t247')
    }
    if(titlesDic[title]){
        return titlesDic[title]
    }
    return title
}