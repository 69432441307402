import { useTranslation } from 'react-i18next';
import { illu5, pattern1 } from '../img';

export default function PageNotFound() {
    const {t} = useTranslation()
    return (
        <div className='page404'>
            <img src={illu5} alt='' className='ill'/>
            <div className='content'>
                <h1>404</h1>
                <p>{t('t137')}</p>
            </div>
            <div className='bg'>
                <img src={pattern1} alt=''/>
            </div>
        </div>
    )
}