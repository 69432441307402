import { useEffect,useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as c from '../components'
import { object, string, ref } from 'yup'
import { Formik,Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import * as imgs from '../img'
import * as a from '../backend/actions'
import { useToast } from '@chakra-ui/react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { c5_ } from '../backend/c'
export function SignIn() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useNavigate()
    const toast = useToast()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const { loading, error, success } = useSelector(state => state.login)
    const iV = {
        email: '',
        password: '',
    }
    const vS = object({
        email: string()
            .required(t('t77'))
        ,
        password: string()
            .required(t('t77'))
        ,
    })
    const submit = (form) => {
        dispatch(a.login(form.email, form.password))
    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
        if (success && !error) {
            history('/user')
        }
        if (queryParams.get('mssg') === 'expired') {
            toast({
                title: t('t93'),
                description: t('t94'),
                status: 'warning',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'login') {
            toast({
                title: t('t95'),
                description: t('t96'),
                status: 'warning',
                isClosable: true,
                duration: 4000
            })
        }
    // eslint-disable-next-line
    }, [success, history, error, toast, location])
    let errorsDic = {
        ERR00001:t('t88'),
        ERR00001x:t('t214')
    }
    if (loading) {
        return <c.Loading />
    }

    return (
        <div className='loginForm'>
            <div className='background n'>
                <img src={imgs.pattern} alt='' />
            </div>
            <Formik initialValues={iV} validationSchema={vS} onSubmit={submit}>
                <Form>
                    <div className='content'>
                        <h1>{t('t8')}</h1>
                        <div className='separator' />
                        <div className='inputs'>
                            <c.InputDiv icon='user' placeholder={t('t13')} name='email' />
                            <c.InputDiv type='password' placeholder={t('t14')} name='password' />
                        </div>
                        <p className='infoActivity'>{t('t212')}</p>
                        {error && <div className='error'>
                            <c.Icon type='xmark' />
                            <p>{errorsDic[error]}</p>
                        </div>}
                        <div className='submitButton'>
                            <button className='bttn expanded' type='submit'>
                                <h4>{t('t16')}</h4>
                                <c.Icon type='chevron-right' width={20} />
                            </button>
                        </div>
                        <h6>{t('t17')} <NavLink to='/signup'>{t('t9')}</NavLink></h6>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}
export function SignUp() {
    const location = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useNavigate()
    const [page, setPage] = useState(1)
    const state = useSelector(state => state.signup)
    const [code1, setCode1] = useState('')
    const [emaill, setEmaill] = useState('')

    const iV = {
        fullName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: ''
    }
	const vS1 = object({
		fullName: string()
			.required(t('t77'))
		,
        email: string()
            .required(t('t77'))
            .email(t('t79'))
    })
    const vS2 = object({
		password: string()
			.required(t('t77'))
			.min(12, t('t80'))
			.matches(/[a-zA-Z]/, t('t81'))
			.matches(/\d/, t('t82'))
		,
		confirmPassword: string()
			.required(t('t77'))
			.oneOf([ref('password')], t('t84'))
	})
    const vS3 = object({
        phoneNumber: string()
		    .required(t('t77'))
		    .test('isValidPhone', t('t78'), (value) => {
		    	if (((value.startsWith('+2126') || value.startsWith('+2127')) && value.length === 13) || ((value.startsWith('06') || value.startsWith('07')) && value.length === 10)) {
		    		return true
		    	}
		    	return isValidPhoneNumber(value)
		    })
    })
    function next(){
        if(page === 6){
            history('/signin')
        }else{
            setPage(page < 5 ? page+1 : 1)
        }
    }
    function submit(form){
        setEmaill(form.email !== '' ? form.email : localStorage.getItem('emailSignUp'))
        if (page === 1) {
            localStorage.setItem('emailSignUp',form.email)
            dispatch(a.createUser({ email: form.email, fullName: form.fullName }, 0))
            .then((success) => {
                if (success) {
                    next()
                }
            })
        }else if(page === 2){
            dispatch(a.createUser({ mode:'email',code:code1,email:emaill }, 1))
            .then((success) => {
                if (success) {
                    next()
                }
            })
        }else if(page ===3){
            dispatch(a.createUser({ password:form.password, email:emaill }, 2))
            .then((success) => {
                if (success) {
                    next()
                }
            })
        }else if(page ===4){
            dispatch(a.createUser({ phoneNumber:form.phoneNumber, email:emaill }, 3))
            .then((success) => {
                if (success) {
                    next()
                    history(location.pathname+'?signup=success')
                }
            })
        }else if(page ===5){
            localStorage.removeItem('emailSignUp')
            history('/signin')
        }
    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
        dispatch({
            type: c5_
        });
    }, [dispatch]);
    if (state.loading) {
        return <c.Loading />
    }
    const PageDisplay = [<c.Welcome />, <c.EmailVer code={code1} setCode={setCode1}/>, <c.Password/>, <c.Phone/>, <c.Done/>]
    const vS = [vS1,null,vS2,vS3,null,null]
    return (
        <div className='loginForm'>
            <div className='progressbar'>
                <div style={{ width: `${(page/5)*100}%`,transition:'.5s'}} />
            </div>
            <div className='form-container'>
                <Formik initialValues={iV} validationSchema={vS[page-1]} onSubmit={submit}>
                    <Form>
                        {PageDisplay[page-1]}
                        <button className={'ctrl next '+(page===5?'done':'')}  type='submit'>
                            <c.Icon type={page===5 ?'check' :'arrow-right'} />
                        </button>
                    </Form>
            	</Formik>
            </div>
            <div className='background n'>
                <img src={imgs.pattern} alt='' />
            </div>
            <h6 className='sub'>{t('t18')} <NavLink to='/signin'>{t('t8')}</NavLink></h6>
        </div>
    )
}
export function ChangePassword() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { loading, userInfo } = useSelector(state => state.userDetails)
    const { error,message } = useSelector(state => state.changePassword)
    const history = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            }, 10);
            if(message==='Done'){
                dispatch({type:'CHANGE_PASS_REQ'})
            }
    // eslint-disable-next-line
    }, []);
    const iV = {
        password0: '',
        password1: '',
        password2: '',
    }
    const errorsDic = {
        ERR00024: t('t102'),
    }
    const vS = object({
        password0: string()
            .required(t('t77'))
        ,
        password1: string()
            .required(t('t77'))
            .min(8, t('t80'))
            .matches(/[a-zA-Z]/, t('t81'))
            .matches(/\d/, t('t82'))
        ,
        password2: string()
            .required(t('t77'))
            .oneOf([ref('password1')], t('t84'))
        ,
    })
    const submit = async (form) => {
        dispatch(a.changePassword({userId:userInfo._id,password:form.password0,newPassword:form.password1},history))
    }
    if (loading) {
        return <c.Loading />
    }
    return (
        <div className='loginForm'>
            <div className='background n'>
                <img src={imgs.pattern} alt='' />
            </div>
            <div className='content'>
                <h1>{t('t73')}</h1>
                <div className='separator' />
                <Formik initialValues={iV} validationSchema={vS} onSubmit={submit}>
                    {({isValid}) =>
                        <Form>
                            <div className='inputs'>
                                <c.InputDiv type='password' icon='lock' placeholder={t('t74')} name='password0' />
                                <c.InputDiv type='password' icon='lock' placeholder={t('t75')} name='password1' />
                                <c.InputDiv type='password' icon='lock' placeholder={t('t76')} name='password2' />
                            </div>
                            {error && <div className='error'>
                                <c.Icon type='xmark' />
                                <p>{errorsDic[error]}</p>
                            </div>}
                            <div className='submitButton'>
                                <button type='submit' className={isValid ? 'bttn' : 'bttn disabled'} >
                                    <h4>{t('t16')}</h4>
                                    <c.Icon type='chevron-right' width={20} />
                                </button>
                            </div>
                        </Form>
                    }
                </Formik>
            </div>
        </div>
    )
}