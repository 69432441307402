import { useEffect, useRef, useState } from 'react'
import { MyButton, Icon } from '../components'
import { useTranslation } from 'react-i18next'
import * as imgs from '../img'
import { useToast } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
export default function Home() {
    const toast = useToast()
    const { t } = useTranslation()
    const location = useLocation()
    const [imgShown, setImgShown] = useState(1)
    const firstRender = useRef(true)
    const queryParams = new URLSearchParams(location.search)
    useEffect(() => {
        if (firstRender.current) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 10);
            firstRender.current = false;
        }
        if (queryParams.get('mssg') === 'error1') {
            toast({
                title: t('t105'),
                status: 'error',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'error2') {
            toast({
                title: t('t106'),
                status: 'error',
                isClosable: true,
                duration: 2000
            })
        }
        const cycleImages = () => {
            setImgShown(imgShown < 4 ? imgShown + 1 : 1)
        }
        const intervalId = setInterval(cycleImages, 3000)
        return () => clearInterval(intervalId)
            // eslint-disable-next-line
        }, [])
    return (
        <>
            <div style={{ height: 70 }} />
            <div className='myCarousel'>
                <div className='topX'>
                    <h1>{t('t134')}</h1>
                    <MyButton isMinimal='scroll' title={t('t46')} icon='chevron-down' type={1} link='#whyUs' />
                </div>
                <div className='imgs'>
                    <img src={imgs.img3} className={imgShown === 1 ? 'show' : 'hide'} alt=''/>
                    <img src={imgs.img4} className={imgShown === 2 ? 'show' : 'hide'} alt=''/>
                    <img src={imgs.img5} className={imgShown === 3 ? 'show' : 'hide'} alt=''/>
                    <img src={imgs.img6} className={imgShown === 4 ? 'show' : 'hide'} alt=''/>
                </div>
            </div>
            <div className='whyUS'>
                <div className='sectionTitle' data-aos='zoom-out-down'>
                    <div className='anchor' id='whyUs' />
                    <h1>{t('t19')}<span>{t('t20')}</span> ?</h1>
                    <div className='decoration'>
                        <div className='x' />
                    </div>
                </div>
                <div className='adv'>
                    <div className='xCard' data-aos='zoom-in'>
                        <div className='ic'><img src={imgs.icon1} alt='' /></div>
                        <h3>{t('t21')}</h3>
                        <p>{t('t22')}</p>
                    </div>
                    <div className='xCard' data-aos='zoom-in' data-aos-delay='300'>
                        <div className='ic'><img src={imgs.icon2} alt='' /></div>
                        <h3>{t('t23')}</h3>
                        <p>{t('t24')}</p>
                    </div>
                    <div className='xCard' data-aos='zoom-in' data-aos-delay='600'>
                        <div className='ic'><img src={imgs.icon3} alt='' /></div>
                        <h3>{t('t25')}</h3>
                        <p>{t('t26')}</p>
                    </div>
                </div>
            </div>
            <section className='whoAlpha' >
                <div className='anchor' id='whoRWe' />
                <div className='sectionTitle' data-aos='zoom-out-down'>
                    <h1>{t('t27')}<span>{t('t28')}</span> ?</h1>
                    <div className='decoration'>
                        <div className='x' />
                    </div>
                </div>
                <div className='content'>
                    <div className='thumb' data-aos='fade-up'>
                        <img src={imgs.illu2} alt='' />
                        <div className='pattern' />
                    </div>
                    <div className='desc' id='ZXS'>
                        <p>
                            {t('t29')}<span>{t('t28')}</span>{t('t30')}
                        </p>
                        <p>{t('t31')}<span>{t('t28')}</span>{t('t32')}</p>
                        <ul>
                            <li>{t('t33')}</li>
                            <li>{t('t34')}</li>
                            <li>{t('t35')}</li>
                            <li>{t('t36')}</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='learnExpert' >
                <div className='content'>
                    <div className='desc'>
                        <h1>{t('t37')}</h1>
                        <p>{t('t38')}</p>
                        <div className='buttonGet' data-aos='zoom-in'>
                            <MyButton title={t('t39')} icon='chevron-right' link='/signin' />
                        </div>
                    </div>
                    <div className='thumb' data-aos='fade-up'>
                        <img src={imgs.img1} alt='' />
                        <div className='pattern' />
                    </div>
                </div>
            </section>
            {/* <section className='previousA' >
                <div className='anchor' id='whoRWe' />
                <div className='sectionTitle' data-aos='zoom-out-down'>
                    <h1>{t('t40')} <span>{t('t41')}</span></h1>
                    <div className='decoration'>
                        <div className='x' />
                    </div>
                </div>
                <div id='testimonialCarousel' className='carousel slide'>
                    <div className='carousel-inner'>
                        <Testimonial content={loremI} profilePicture={imgs.pf1} userName='Sam Davidson' role='Businessman' active={true} />
                        <Testimonial content={loremI} profilePicture={imgs.pf2} userName='Jean Bult' role='DFA' />
                        <Testimonial content={loremI} profilePicture={imgs.pf3} userName='Nicole Kardin' role='Administrator' />
                        <Testimonial content={loremI} profilePicture={imgs.pf4} userName='Sam Kazan' role='CEO' />
                        <Testimonial content={loremI} profilePicture={imgs.pf5} userName='Jessica Emmet' role='Student' />
                    </div>
                    <button className='carouselIc carousel-control-prev' type='button' data-bs-target='#testimonialCarousel' data-bs-slide='prev'>
                        <Icon type='chevron-left' />
                        <span className='visually-hidden'>Previous</span>
                    </button>
                    <button className='carouselIc carousel-control-next' type='button' data-bs-target='#testimonialCarousel' data-bs-slide='next'>
                        <Icon type='chevron-right' />
                        <span className='visually-hidden'>Next</span>
                    </button>
                </div>
            </section> */}
            <section className='quote'>
                <div className='content'>
                    <div className='quoteIcon' data-aos='fade-right' data-aos-delay='500'>
                        <Icon type='quote-left' />
                        <Icon type='quote-right' />
                    </div>
                    <h1 data-aos='fade-up'>{t('t42')}Alpha Learner</h1>
                    <div className='quoteOwner' data-aos='zoom-in-right'>
                        <div>
                            <h5>Youssef Majdoune</h5>
                            <p>{t('t43')} Alpha Learner</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='waitingFor'>
                <h1>{t('t44')}</h1>
                <MyButton title={t('t45')} icon='chevron-right' link='/courses' />
            </section>
        </>
    )
}