import { Icon } from '.'
export default function Rating({ n }) {
    function Star1({ times }) {
        const divs = [];
        for (let i = 0; i < times; i++) {
            divs.push({ type: 'star', regular: false });
        }
        for (let i = 0; i < 5 - times; i++) {
            divs.push({ type: 'star', regular: true });
        }
        return <>{divs.map((icon, i) => <Icon type={icon.type} regular={icon.regular} key={i} />)}</>;
    }
    function Star2({ times }) {
        const divs = [];
        const k = Math.floor(times)
        for (let i = 0; i < k; i++) {
            divs.push({ type: 'star', regular: false });
        }
        divs.push({ type: 'star-half-stroke', regular: true });
        for (let i = 0; i < 4.5 - times; i++) {
            divs.push({ type: 'star', regular: true });
        }
        return <>{divs.map((icon, i) => <Icon type={icon.type} regular={icon.regular} key={i} />)}</>;
    }
    function check(n) {
        if (n % 1 === 0) {
            return <Star1 times={n} />
        } else {
            return <Star2 times={n} />
        }
    }
    return (
        <div className='stars'>
            {check(n)}
        </div>
    );
}

