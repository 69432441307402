export default function Icon({ brand, type, width, regular, color,onClick,id }) {
    if (brand) {
        return <i id={id} className={'fa-brands fa-' + type} style={{ fontSize: width, color }} />
    }
    if (regular) {
        return <i id={id} className={'fa-regular fa-' + type} style={{ fontSize: width, color }} />
    }
    return (
        <i id={id} className={'fa-solid fa-' + type} style={{ fontSize: width, color }} onClick={onClick}/>
    )
}
