import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Icon } from '../components'
import * as imgs from '../img'
export default function Contact() {
    const { t } = useTranslation()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <div className='contact'>
            <div className='pic'>
                <img src={imgs.img2} alt='' />
            </div>
            <div className='det' data-aos='fade-up'>
                <div className='topDet'>
                    <h1>{t('t49')}</h1>
                    <p>{t('t50')}</p>
                </div>
                <div className='contactDetails'>
                    <div className='bottomC'>
                        <a className='cRow' target='_blank' rel='noreferrer' href='https://wa.me/message/A5ZNX4XRZTESE1'>
                            <div className='iconN'>
                                <Icon type='whatsapp' brand={true} />
                            </div>
                            <h3>+2126.94.12.87.08</h3>
                        </a>
                        <a className='cRow' href='tel:212694128708'>
                            <div className='iconN'>
                                <Icon type='phone' />
                            </div>
                            <h3>+2126.94.12.87.08</h3>
                        </a>
                        <a className='cRow' target='_blank' rel='noreferrer' href='mailto:contact@alphalearner.ma'>
                            <div className='iconN'>
                                <Icon type='envelope' />
                            </div>
                            <h3>contact@alphalearner.ma</h3>
                        </a>
                    </div>
                </div>
                <div className='icons'>
                        <a className='iconContainer' href='https://www.instagram.com/alpha_learner.official/' rel='noreferrer' target='_blank'>
                            <Icon type='instagram' brand width={22}/>
                        </a>
                        <a className='iconContainer' href='https://www.linkedin.com/in/alpha-learner-aa02ba323/' rel='noreferrer' target='_blank'>
                            <Icon type='linkedin' brand width={22}/>
                        </a>
                        <a className='iconContainer' href='https://x.com/alpha_learner1' rel='noreferrer' target='_blank'>
                            <Icon type='twitter' brand width={22}/>
                        </a>
                        <a className='iconContainer' href='https://www.tiktok.com/@alpha_learner' rel='noreferrer' target='_blank'>
                            <Icon type='tiktok' brand width={22}/>
                        </a>
                        <a className='iconContainer' href='https://www.facebook.com/people/Alphalearner/61562975821177/' rel='noreferrer' target='_blank'>
                            <Icon type='facebook' brand/>
                        </a>
                    </div>
            </div>
        </div>
    )
}
