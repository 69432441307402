import { useEffect,useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { buyRequest, getCourse,getUserBuyRequests } from '../backend/actions'
import { Radio, RadioGroup } from '@chakra-ui/react'
import { Icon, Loading } from '../components'
import { boolean, object, string } from 'yup'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { icon7 } from '../img'
import { Checkbox } from 'primereact/checkbox'
import { checkForTitleTranslation } from '.'

export default function BuyCourse() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useNavigate()
    const { userInfo} = useSelector(state => state.userDetails)
    const { loading:loading1, course } = useSelector(state => state.course)
    const { loading:loading2 } = useSelector(state => state.buyRequest)
    const { requests } = useSelector(state => state.getUserBuyRequests)
    const [error, setError] = useState(null)
    var iV = {
        bFullName:userInfo ? userInfo.fullName : '',
        bPhoneNumber:userInfo ? userInfo.phoneNumber : '',
        bEmail:userInfo ? userInfo.email : '',
        bLevel:'',
        bCity:'',
        bAgreedInfo:false,
    }
    const levels = [t('t138'), 'BAC', 'BAC+2', t('t139'), 'Master', 'Doctorat']
    const vS = object({
        bFullName:string()
            .required(t('t77')),
        bPhoneNumber:string()
            .required(t('t77'))
            .test('isValidPhone', t('t78'), (value) => {
		    	if (((value.startsWith('+2126') || value.startsWith('+2127')) && value.length === 13) || ((value.startsWith('06') || value.startsWith('07')) && value.length === 10)) {
		    		return true
		    	}
		    	return isValidPhoneNumber(value)
		    }),
        bEmail:string()
            .required(t('t77'))
            .email(t('t79')),
        bLevel:string()
            .required(t('t77')),
        bCity:string()
            .required(t('t77')),
        bAgreedInfo:boolean()
            .required(t('t77'))
            .oneOf([true], t('t140')),
    })
    const errorsDic = {
        ERR_njGQmmt3io:t('t85'),
        ERR_4HcjjwewUG:t('t86'),
    }
    const {id}=useParams()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
        if(userInfo){
            dispatch(getCourse(id))
            dispatch(getUserBuyRequests())
            if(requests){
                if(requests.filter(request => request.course === id).length !== 0){
                    history(-1)
                }
            }
        }
        // eslint-disable-next-line
    }, [history,dispatch,id,userInfo])
    function submit(form){
        dispatch(buyRequest({
            userId:userInfo ? userInfo._id : 'NO_USER',
            courseId:course._id,
            fullName:form.bFullName,
            email:form.bEmail,
            phoneNumber:form.bPhoneNumber,
            city:form.bCity,
            level:form.bLevel,
            courseTitle:checkForTitleTranslation(course.title,t),
            courseDescription:t('t162')
        }))
        .then((success) => {
            if (success) {
                history('/course-bought-success')
            }
        })
        .catch((err)=>{
            setError(errorsDic[err.message])
        })
    }
    if(loading1 || loading2 || !course ){
        return <Loading/>
    }
    return (
        <>
            <div style={{height:180}}/>
            <div className='courseToBuy'>
                <div className='thumbnail'>
                    <img src={`/coursesFolder/${course.folderId}/thumb.jpg`} alt='' />
                </div>
                <div className='details'>
                    <div>
                        <h1>{checkForTitleTranslation(course.title,t)}</h1>
                        <p>{checkForTitleTranslation(course.littleDescription,t)}</p>
                    </div>
                    <div className="prices">
                        <h3>{course.price} MAD</h3>
                        <h3>{course.befPrice} MAD</h3>
                    </div>
                </div>
                <div className="promo">
                    <p>-{course.promotion}%</p>
                </div>
            </div>
            <div className='buyForm'>
                <Formik initialValues={iV} validationSchema={vS} onSubmit={submit}>
                {({ values, handleChange }) => 
                    { 
                        return <Form>
                        <div className='inputRow'>
                            <Field type='text' placeholder={t('t12')} name='bFullName' />
                            <Field type='text' placeholder={t('t48')} name='bPhoneNumber' />
                        </div>
                        <div className='inputRow'>
                            <Field type='text' placeholder={t('t13')} name='bEmail' />
                            <Field type='text' placeholder={t('t147')} name='bCity' />
                        </div>
                        <div className='educLevel'>
                            <RadioGroup name='bLevel' >
                                {levels.map(lvl => <Radio key={lvl} value={lvl} checked={values.bLevel === lvl} onChange={handleChange}>{lvl}</Radio>)}
                            </RadioGroup>
                        </div>
                        <label className='agreedInfo n'>
                            <Checkbox onChange={handleChange} checked={values.bAgreedInfo} name='bAgreedInfo'></Checkbox>
                            <p>{t('t141')}</p>
                        </label>
                        <p className='msg'>{t('t142')}</p>
                        <div className='submitButton'>
                            <button className='bttn expanded' type='submit' >
                                <h4>{t('t16')}</h4>
                                <Icon type='chevron-right' width={20} />
                            </button>
                        </div>
                        {error && <div className='error'>
                            <Icon type='xmark' />
                            <p>{error === true ? t('t154') : error}</p>
                        </div>}
                        <ErrorMessage name='bFullName' >
                            {msg => {
                                if(msg===t('t77')){
                                    setError(true)
                                }else{
                                    setError(null)
                                    return <div className='error'>
                                        <Icon type='xmark' />
                                        <p>{msg}</p>
                                    </div>
                                }
                            }}
                        </ErrorMessage>
                        <ErrorMessage name='bPhoneNumber' >
                            {msg => {
                                if(msg===t('t77')){
                                    setError(true)
                                }else{
                                    setError(null)
                                    return <div className='error'>
                                        <Icon type='xmark' />
                                        <p>{msg}</p>
                                    </div>
                                }
                            }}
                        </ErrorMessage>
                        <ErrorMessage name='bEmail' >
                            {msg => {
                                if(msg===t('t77')){
                                    setError(true)
                                }else{
                                    setError(null)
                                    return <div className='error'>
                                        <Icon type='xmark' />
                                        <p>{msg}</p>
                                    </div>
                                }
                            }}
                        </ErrorMessage>
                        <ErrorMessage name='bCity' >
                            {msg => {
                                if(msg===t('t77')){
                                    setError(true)
                                }else{
                                    setError(null)
                                    return <div className='error'>
                                        <Icon type='xmark' />
                                        <p>{msg}</p>
                                    </div>
                                }
                            }}
                        </ErrorMessage>
                        <ErrorMessage name='bLevel' >
                            {msg => {
                                if(msg===t('t77')){
                                    setError(true)
                                }else{
                                    setError(null)
                                    return <div className='error'>
                                        <Icon type='xmark' />
                                        <p>{msg}</p>
                                    </div>
                                }
                            }}
                        </ErrorMessage>
                        <ErrorMessage name='bAgreedInfo' >
                            {msg => <div className='error'>
                                <Icon type='xmark' />
                                <p>{msg}</p>
                            </div>}
                        </ErrorMessage>
                    </Form>}}
                </Formik>
            </div>
        </>
    )
}
export function BuySuccess(){
    const history = useNavigate()
    const {t} = useTranslation()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
    }, [])

    return <div className='successCoContainer'>
        <div style={{height:100}} />
        <div className='successContainer'>
            <div className='thumb'>
                <img src={icon7} alt='' />
            </div>
            <div className='desc'>
                <h1>{t('t155')}</h1>
                <h3>{t('t156')}</h3>
                <button className='outlinedBtn' onClick={()=>{history('/')}}>
                    <h5>{t('t157')}</h5>
                </button>
            </div>
        </div>
        <div style={{height:50}} />
    </div>
}