import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
i18next.use(initReactI18next).init({
    resources:{
        en:{
            translation:{
                t1:'Home',
                t2:'Courses',
                t3:'Contact',
                t4:'English',
                t5:'French',
                t6:'Morocco',
                t7:'Thanks to our experience, our expertise and the diversity of the projects carried out, our team analyzes your needs and provides you with objective advice.',
                t8:'Log In',
                t9:'Sign Up',
                t10:' aspires to stand as the #1 learning platform exclusively for the ',
                t11:'Real Alpha Learners',
                t12:'Full Name',
                t13:'Email Address',
                t14:'Password',
                t15:'Confirm Password',
                t16:'Submit',
                t17:'Don\'t have an account ? ',
                t18:'Already have an account ?',
                t19:'WHY ',
                t20:'US',
                t21:'Diverse Courses',
                t22:'We provide diverse array of courses spanning various subjects and skill levels.',
                t23:'Instructor Support',
                t24:'We support our trainees with comprehensive resources, instructors, and analytics tools.',
                t25:'User-Friendly Interface',
                t26:'We take pride in offering a stunning and intuitive user interface to our Alpha Learners',
                t27:'WHO IS THE ',
                t28:'Alpha Learner',
                t29:'An ',
                t30:', a passionate and intellectually curious learner, is driven by an insatiable desire to understand how things work. With her sharp intellect and relentless pursuit of improvement, she thrives on challenges, viewing each obstacle as an opportunity for growth. Their analytical mindset and commitment to excellence fuel her journey of continuous learning and self-discovery.',
                t31:'The characteristics of an ',
                t32:' includes :',
                t33:'Driven by learning',
                t34:'Embraces challenges',
                t35:'Committed to excellence',
                t36:'Lifelong learner',
                t37:'Gain knowledge from professionals in a worldwide network of expertise.',
                t38:'Want to develop your skills ? It starts right here.',
                t39:'Get in',
                t40:'PREVIOUS',
                t41:' ALPHA LEARNERS',
                t42:'We looked carefully at people\'s tranformative learning desires and needs, we came up with a brilliant idea, ',
                t43:'Founder of ',
                t44:'What are you waiting for ?',
                t45:'Jump to courses right now',
                t46:'Discover',
                t47:'A well-designed selection of courses',
                t48:'Phone Number',
                t49:'Let us help you',
                t50:'Questions, requests, advices. Our team is always available to answer your demandes !',
                t51:'Bestseller',
                t52:'Created by ',
                t53:'Add to my watch list',
                t54:'Buy Now',
                t55:'For whom this course :',
                t56:'What you will acquire :',
                t57:'Requirements :',
                t58:'Description :',
                t59:'Ratings :',
                t60:'Welcome Back',
                t61:'Your courses',
                t62:'Your progress',
                t63:'Average Minutes per day',
                t64:'Percentage of completed courses',
                t65:'Courses Completed',
                t66:'Streak day',
                t67:'Your infos',
                t68:'Name :',
                t69:'Email :',
                t70:'Phone :',
                t71:'Password :',
                t72:'Change',
                t73:'Change Password',
                t74:'Old Password',
                t75:'New Password',
                t76:'Confirm New Password',
                t77:'Required',
                t78:'Phone number is not valid',
                t79:'Email is not valid',
                t80:'Password must be at least 12 characters',
                t81:'Password must contain at least one letter',
                t82:'Password must contain at least one number',
                t83:'Password must not have consecutive numbers',
                t84:'Passwords must match',
                t85:'Email is already used',
                t86:'Phone number is already used',
                t87:'Something went wrong',
                t88:'Incorrect email or password',
                t89:'Hello ',
                t90:'Log Out',
                t91:'Your watchlist',
                t92:'Let\'s go for some new courses',
                t93:'Session Expired.',
                t94:'Please re-login to the plateform',
                t95:'Login Required',
                t96:'Please login to the plateform',
                t97:'Done',
                t98:'This course is in your watchlist',
                t99:'Action not completed',
                t100:'This course is already in your watchlist',
                t101:'See your past progress',
                t102:'Old Password is incorrect',
                t103:'Your password has changed',
                t104:'Course Description',
                t105:'Some error occured',
                t106:'Action Not Allowed',
                t107:'Photo Profile Changed',
                t108:'An error occured',
                t109:'Profile Photo',
                t110:'Choose Photo',
                t111:'Discard',
                t112:'Apply',
                t113:'Your comment has been uploaded',
                t114:'Your point of view matters',
                t115:'Post',
                t116:'Patience is always the key',
                t117:'Email is not valid',
                t118:'Welcome',
                t119:'Email Verification',
                t120:'Password',
                t121:'Phone',
                t122:'Phone Verification',
                t123:'Done',
                t124:'Congratulations, you\'re now considered',
                t125:'an ',
                t126:'Now, just login with your credentials',
                t127:'Enter the code sent to ',
                t128:'Admin Panel',
                t129:'Secured, token:',
                t130:'Download Excel Spreadsheet',
                t131:'Close',
                t132:'Add',
                t133:'Joined at',
                t134:'Looking for a course ? You just came to the right place !',
                t135:'Code is incorrect',
                t136:'Your code here',
                t137:'Looks like you\'re lost',
                t138:'Student',
                t139:'License',
                t140:'You must accept information truthfulness',
                t141:'I agree on giving legit and truthful information',
                t142:'We will contact you on your phone to confirm the purchase.',
                t143:'Courses',
                t144:'Purchases',
                t145:'Only With Purchases',
                t146:'Select a course',
                t147:'City',
                t148:'Education Level',
                t149:'Price',
                t150:'State',
                t151:'Pending',
                t152:'Approved',
                t153:'Denied',
                t154:'Please fill out all the required fields',
                t155:'Success',
                t156:'Your purchase request has been sent.',
                t157:'Go back home',
                t158:'Content',
                t161:'Master Excel and unleash its power!',
                t162:'Develop essential skills for your career with our complete Excel course.',
                t163:'Sign up now',
                t164:'Why Learn Excel?',
                t165:'Discover how Excel can transform your work and help you manage data more efficiently.',
                t166:'Save time with automated functions',
                t167:'Analyze data like a pro',
                t168:'Increase your value in the job market',
                t169:'You’re new to Excel and want to learn with a progressive, guided approach focused on practical exercises.',
                t170:'You’re transitioning careers and looking to boost your confidence, use Excel intuitively, and validate your skills through concrete workshops designed for you.',
                t171:'You need to pass a competency test for a job application, and you want to approach it with confidence and excel in it.',
                t172:'You use Excel regularly but want to adopt best practices to improve your productivity.',
                t173:'Develop key skills',
                t174:'Strengthen your resume with skills valued by recruiters, appreciated in all companies, and that will stay with you forever.',
                t175:'Become truly efficient',
                t176:'Learn the best practices and work methods that will allow you to handle your data with ease while doubling your productivity.',
                t177:'Acquire the skill',
                t178:'Master the essential basics to approach every task with confidence. Stay calm, face any challenge with assurance, and stand out from your peers.',
                t179:'Frequently Asked Questions',
                t180:'How long does the course last?',
                t181:'The course lasts about 4 hours, spread across several modules accessible at your own pace.',
                t182:'Can I get a refund?',
                t183:'Yes, you have a 15-day money-back guarantee if you’re not satisfied.',
                t184:'What level of Excel is required to start?',
                t185:'Our course is suitable for both beginners and intermediate users.',
                t186:'Any questions?',
                t187:'Ask whatever you want, we’ll answer you',
                t188:'By Email',
                t189:'By WhatsApp',
                t190:'Ready to Transform Your Excel Skills?',
                t191:'Sign up today and start mastering Excel with our complete program accessible to all levels.',
                t192:'Contact us',
                t193:'This ',
                t194:'program ',
                t195:'is ',
                t196:'yours ',
                t197:'if',
                t198:'Start Now',
                t199:'A ',
                t200:'complete program ',
                t201:'for all levels',
                t202:'Introduction to Excel',
                t203:'Workbooks, sheets, and cells',
                t204:'Data Entry',
                t205:'Text Manipulation',
                t206:'Essential functions and formulas',
                t207:'the IF() function',
                t208:'Data\'s sorting and filtering',
                t209:'Purchase Request',
                t210:'Requested on',
                t211:'Confirmed at',
                t212:'You get automatically disconnected after 15 days of inactivity',
                t213:'Code is incorrect',
                t214:'Devices number limit reached',
                t215:'A certificate is given at the end of the training',
                t216:'Data Validation in Excel',
                t217:'Elevate your data analysis skills with our comprehensive intermediate and advanced Excel course. Perfect for professionals aiming to become Excel power users.',
                t218:'Enroll Now',
                t219:'Contact us',
                t220:'Who is this course for?',
                t221:'This course is designed for individuals who want to improve their basic knowledge of Excel and take their skills to the next level. It\'s perfect for:',
                t222:'Professionals looking to enhance their data analysis capabilities',
                t223:'Students aiming to stand out in the job market',
                t224:'Excel users who want to move beyond basic formulas and functions',
                t225:'Anyone interested in mastering intermediate to advanced Excel techniques',
                t226:'Course Curriculum',
                t227:'Discover basic validation',
                t228:'Detect and personalise validations',
                t229:'Dropdowns in Excel',
                t230:'Make validations more professionnal',
                t231:'What Our Students Say',
                t232:'This course has transformed the way I work with data. I\'m now much more efficient and confident in my Excel skills.',
                t233:'The advanced formula techniques I learned have saved me hours of work each week. Highly recommended!',
                t234:'The instructor\'s expertise and clear explanations made complex concepts easy to understand and apply.',
                t235:'Once enrolled, you have lifetime access to the course materials, including any future updates.',
                t236:'Yes, we offer a 15-day money-back guarantee. If you\'re not satisfied with the course, you can request a full refund within 15 days of purchase.',
                t237:'This course is designed for those with basic Excel knowledge looking to advance their skills. Familiarity with basic functions and formulas is recommended.',
                t238:'The course is delivered online through our learning platform. You can access the video lessons, exercises, and resources at your own pace.',
                t239:'Marketing Manager',
                t240:'Financial Analyst',
                t241:'Data Scientist',
                t242:'How long do I have access to the course?',
                t243:'Is there a money-back guarantee?',
                t244:'Do I need any prior experience?',
                t245:'How is the course delivered?',
                t246:'Pivot Tables & Graphs',
                t247:'Unlock the full potential of Excel with our comprehensive course on Pivot Tables & Graphs. Transform raw data into powerful insights and stunning visualizations.',
                t248:'The training lasts approximately 2 and a half hours, spread over several modules accessible at your own pace.',
                t248_:'The training lasts approximately 2 hours, spread over several modules accessible at your own pace.',
                t249:'Which Excel version would I need for this course ?',
                t250:'Any excel version is totally fine, but any version above Excel 2013 is recommanded',
                t251:'Introduction to Pivot Tables',
                t252:'Data Organization for Pivot Tables',
                t253:'Advanced Pivot Table Techniques',
                t254:'Calculated Fields and Items',
                t255:'Pivot Table Formatting and Layouts',
                t256:'Challenges and solutions',
                t257:'Excel Chart Fundamentals',
                t258:'Advanced Chart Types and Customization',
                t259:'Dynamic Charts with Formulas',
                t260:'Data Visualization Best Practices',
                t261:'Interactive Dashboards with Charts',
                t262:'Power BI Integration for Advanced Visuals',
                t263:'Pivot Tables & Graphs',
                t264:'Unlock the full potential of Excel with our comprehensive course on Pivot Tables & Graphs. Transform raw data into powerful insights and stunning visualizations.',
                t265:'What You\'ll Master',
                t266:'Pivot Tables',
                t267:'Learn to summarize, analyze, and present complex data with ease.',
                t268:'Dynamic Graphs',
                t269:'Create compelling visualizations that bring your data to life.',
                t270:'Data Modeling',
                t271:'Build robust data models for complex analysis and reporting.',
                t272:'Trend Analysis',
                t273:'Identify and visualize trends to make data-driven decisions.',
                t274:'Why exactly this course ?',
                t275:'Pivot tables and graphs in Excel are essential tools for companies to analyze and visualize large datasets efficiently. Pivot tables allow users to summarize, organize, and filter complex information, making it easier to spot trends, patterns, and outliers. Graphs complement this by turning raw data into visual representations, helping decision-makers understand insights at a glance. In real-life scenarios, businesses rely on these tools for tasks like sales reporting, financial analysis, and inventory management, enabling faster, data-driven decisions that enhance productivity and performance.',
                t276:'Why Pivot Tables?',
                t277:'Quickly summarize large datasets with drag-and-drop simplicity',
                t278:'Analyze data from multiple angles with just a few clicks',
                t279:'Create custom calculations and groupings for deeper insights',
                t280:'Generate reports in minutes instead of hours',
                t281:'What You\'ll Learn',
                t282:'Why Excel Graphs?',
                t283:'Transform complex data into clear, compelling visuals',
                t284:'Customize charts to perfectly fit your data story',
                t285:'Create interactive dashboards for dynamic data exploration',
                t286:'Master advanced techniques for impactful presentations',
                t287:'What You\'ll Learn',
                t288:'Pivot Table Mastery',
                t289:'Graph Expertise',
                t290:'Ready to Transform Your Excel Skills?',
                t291:'Join thousands of professionals who have accelerated their careers with our comprehensive Excel Mastery course.',
                t292:'Enroll Now and Save 40%',
                t293:'Master ',
                t294:'Pivot Tables',
                t295:'Create Stunning ',
                t296:'Graphs',
                t297:'Course ',
                t298:'Curriculum',
                t299:'Start Learning Now',
                t300:'View Course Syllabus',
                t301:'',
                t302:'',
                t303:'',
                t304:'',
                t305:'',
                t306:'',
                t307:'',
                t308:'',
                t309:'',
                t310:'',
                t311:'',
                t312:'',
                t313:'',
                t314:'',
                t315:'',
            }
        },
        fr:{
            translation:{
                t1:'Acceuil',
                t2:'Formations',
                t3:'Contact',
                t4:'Anglais',
                t5:'Français',
                t6:'Maroc',
                t7:'Grâce à notre expérience, notre expertise et la diversité des projets réalisés, notre équipe analyse vos besoins et vous assure un conseil objectif.',
                t8:'Se connecter',
                t9:'S\'inscrire',
                t10:' aspire à être la plateforme d\'apprentissage #1 exclusivement destinée aux ',
                t11:'Vrais Alpha Étudiants',
                t12:'Nom Complet',
                t13:'Adresse Email',
                t14:'Mot de Passe',
                t15:'Confirmer le Mot de Passe',
                t16:'Soumettre',
                t17:'Vous n\'avez pas de compte ? ',
                t18:'Vous avez déjà un compte ?',
                t19:'POURQUOI ',
                t20:'NOUS',
                t21:'Cours Diversifiés',
                t22:'Nous proposons une large gamme de cours couvrant divers sujets et niveaux de compétence.',
                t23:'Soutien des Instructeurs',
                t24:'Nous soutenons nos apprenants avec des ressources complètes, des instructeurs et des outils d\'analyse.',
                t25:'Interface Conviviale',
                t26:'Nous sommes fiers d\'offrir une interface utilisateur intuitive à nos Étudiants Alphas.',
                t27:'QUI EST L\'',
                t28:'Étudiant Alpha',
                t29:'Un ',
                t30:', un apprenant passionné et intellectuellement curieux, est animé par un désir insatiable de comprendre le fonctionnement des choses. Avec son intellect aiguisé et sa recherche incessante d\'amélioration, il prospère face aux défis, considérant chaque obstacle comme une opportunité de croissance. Son état d\'esprit analytique et son engagement envers l\'excellence alimentent son parcours d\'apprentissage continu et de découverte de soi.',
                t31:'Les caractéristiques de l\'',
                t32:' incluent :',
                t33:'Animé par l\'apprentissage',
                t34:'Relève les défis',
                t35:'Engagé envers l\'excellence',
                t36:'Apprenant à vie',
                t37:'Acquérez des connaissances auprès de professionnels dans un réseau mondial d\'expertise.',
                t38:'Vous voulez développer vos compétences ? Tout commence ici.',
                t39:'Rejoignez les',
                t40:'Précédents',
                t41:'Étudiants Alpha',
                t42:'Nous avons examiné attentivement les désirs et besoins en matière d\'apprentissage des personnes, nous avons eu une idée brillante, ',
                t43:'Fondateur de ',
                t44:'Qu\'attendez-vous ?',
                t45:'Voir les formations maintenant',
                t46:'Découvrir',
                t47:'Une sélection de formations bien conçue',
                t48:'N° Téléphone',
                t49:'Laissez-nous vous aider',
                t50:'Questions, requêtes, conseils. Notre équipe est toujours disponible pour répondre à vos demandes !',
                t51:'Meilleure vente',
                t52:'Créé par ',
                t53:'Ajouter à ma liste de suivi',
                t54:'Acheter maintenant',
                t55:'Pour qui ce cours :',
                t56:'Ce que vous allez acquérir :',
                t57:'Prérequis :',
                t58:'Description :',
                t59:'Évaluations :',
                t60:'Rebienvenue',
                t61:'Vos cours',
                t62:'Votre progression',
                t63:'Moyenne de minutes par jour',
                t64:'Pourcentage de cours terminés',
                t65:'Cours terminés',
                t66:'Jours de Série',
                t67:'Vos informations',
                t68:'Nom :',
                t69:'Email :',
                t70:'Téléphone :',
                t71:'Mot de passe :',
                t72:'Changer',
                t73:'Changer votre mot de passe',
                t74:'Ancien mot de passe',
                t75:'Nouveau mot de passe',
                t76:'Confirmer nouveau mot de passe',
                t77:'Obligatoire',
                t78:'Le numéro de téléphone n\'est pas valide',
                t79:'L\'email n\'est pas valide',
                t80:'Mot de passe doit être au moins 12 caractères',
                t81:'Le mot de passe doit contenir au moins une lettre',
                t82:'le mot de passe doit contenir au moins un chiffre',
                t83:'Le mot de passe ne doit pas comporter de chiffres consécutifs',
                t84:'Les mots de passe doivent correspondre',
                t85:'Adresse mail est déjà utilisée',
                t86:'Numéro de téléphone est déjà utilisé',
                t87:'Quelque chose est mal passé',
                t88:'Email ou mot de passe incorrect',
                t89:'Salut ',
                t90:'Se Déconnecter',
                t91:'Votre liste de suivi',
                t92:'Allons-y pour des nouvelles formations',
                t93:'Session expirée.',
                t94:'Veuillez vous reconnecter à la plateforme.',
                t95:'Connexion requise',
                t96:'Veuillez vous connecter à la plateforme.',
                t97:'Terminé',
                t98:'Ce cours est dans votre liste de suivi.',
                t99:'Action non effectuée',
                t100:'Ce cours est déjà dans votre liste de suivi.',
                t101:'Consultez votre progrès antérieurs',
                t102:'Ancien mot de passe est incorrecte',
                t103:'Votre mot de passe a été changé',
                t104:'Description de la formation',
                t105:'Une erreur s\'est produite',
                t106:'Action Non Autorisé',
                t107:'Photo de profil changée',
                t108:'Une erreur est survenue',
                t109:'Photo de profil',
                t110:'Choisir une photo',
                t111:'Annuler',
                t112:'Appliquer',
                t113:'Votre commentaire a été ajouté',
                t114:'Votre point de vue est important',
                t115:'Publier',
                t116:'La patience est toujours la clé',
                t117:'L\'email n\'est pas valide',
                t118:'Bienvenue',
                t119:'Vérification de l\'email',
                t120:'Mot de passe',
                t121:'Téléphone',
                t122:'Vérification du téléphone',
                t123:'Terminé',
                t124:'Félicitations, vous êtes maintenant considéré',
                t125:'un ',
                t126:'Maintenant, connectez-vous simplement avec votre identifiant',
                t127:'Saisir le code envoyé à',
                t128:'Panneau d\'administration',
                t129:'Sécurisé, jeton :',
                t130:'Télécharger la feuille de calcul Excel',
                t131:'Fermer',
                t132:'Ajouter',
                t133:'Rejoint le',
                t134:'Vous cherchez une formation ? Vous êtes au bon endroit !',
                t135:'Code est incorrect',
                t136:'Votre code ici',
                t137:'On dirait que vous êtes perdu',
                t138:'Étudiant',
                t139:'Licence',
                t140:'Vous devez accepter la validité des informations',
                t141:'Je consens à fournir des informations valides',
                t142:'Nous vous contacterons par téléphone pour confirmer l\'achat.',
                t143:'Formations',
                t144:'Achats',
                t145:'Seulement avec des achats',
                t146:'Sélectionnez une formation',
                t147:'Ville',
                t148:'Niveau d\'éducation',
                t149:'Prix',
                t150:'État',
                t151:'En attente',
                t152:'Accepté',
                t153:'Refusé',
                t154:'Veuillez remplir tous les champs requis',
                t155:'Succès',
                t156:'Votre demande d\'achat a été envoyée.',
                t157:'Retour à l\'accueil',
                t158:'Contenu',
                t159:'Erreur',
                t160:'Vous avez déjà demandé cette formation',
                t161:'Maîtrisez Excel et libérer sa puissance !',
                t162:'Développez des compétences essentielles pour votre carrière avec notre formation complète sur Excel.',
                t163:'Inscrivez-vous dès maintenant',
                t164:'Pourquoi Apprendre Excel ?',
                t165:'Découvrez comment Excel peut transformer votre façon de travailler et vous permettre de gérer les données plus efficacement.',
                t166:'Gagnez du temps avec des fonctions automatisées',
                t167:'Analysez des données comme un pro',
                t168:'Augmentez votre valeur sur le marché du travail',
                t169:'Vous débutez sur Excel et souhaitez apprendre avec une méthode progressive, guidée, et axée sur la pratique grâce à des exercices applicatifs.',
                t170:'Vous êtes en reconversion professionnelle et cherchez à renforcer votre confiance, utiliser Excel de manière intuitive, et valider vos acquis à travers des ateliers concrets conçus pour vous.',
                t171:'Vous devez passer un test de compétences pour postuler à un poste, et vous voulez l\'aborder avec confiance et le réussir brillamment.',
                t172:'Vous utilisez Excel régulièrement, mais vous souhaitez adopter les meilleures pratiques pour améliorer votre productivité.',
                t173:'Développez des compétences clés',
                t174:'Renforcez votre CV avec des compétences prisées par les recruteurs, valorisées dans toutes les entreprises, et qui resteront avec vous pour toujours.',
                t175:'Devenez vraiment efficace',
                t176:'Apprenez les bonnes pratiques et les méthodes de travail qui vous permettront de manipuler vos données avec aisance, tout en doublant votre productivité.',
                t177:'Acquérir la compétence',
                t178:'Maîtrisez les bases indispensables pour aborder chaque tâche avec confiance. Restez serein, affrontez tous les défis avec assurance, et démarquez-vous de vos pairs.',
                t179:'Questions Fréquentes',
                t180:'Combien de temps dure la formation ?',
                t181:'La formation dure environ 4 heures, réparties sur plusieurs modules accessibles à votre rythme.',
                t182:'Puis-je obtenir un remboursement ?',
                t183:'Oui, vous avez une garantie de remboursement de 15 jours si vous n\'êtes pas satisfait.',
                t184:'Quel niveau d\'Excel est requis pour commencer ?',
                t185:'Notre formation est adaptée aux débutants comme aux utilisateurs intermédiaires.',
                t186:'Des questions ?',
                t187:'Demandez ce que vous voulez, nous allons vous répondre',
                t188:'Par Email',
                t189:'Par WhatsApp',
                t190:'Prêt à Transformer Vos Compétences en Excel ?',
                t191:'Inscrivez-vous dès aujourd\'hui et commencez à maîtriser Excel avec notre programme complet et accessible à tous les niveaux.',
                t192:'Contactez-nous',
                t193:'Ce ',
                t194:'programme',
                t195:' est le ',
                t196:'vôtre',
                t197:' si',
                t198:'Commencez Maintenant',
                t199:'un ',
                t200:'programme complet',
                t201:' pour tous les niveaux',
                t202:'Introduction à Excel',
                t203:'Classeurs, feuilles et cellules',
                t204:'Saisie des données',
                t205:'Manipulation du texte',
                t206:'Fonctions et Formules Essentielles',
                t207:'La fonction SI()',
                t208:'Tri et filtrage des données',
                t209:'Demande d\'achat ',
                t210:'Demandé le',
                t211:'Confirmé le',
                t212:'Vous serez automatiquement déconnecté après 15 jours d\'inactivité',
                t213:'Code est incorrecte',
                t214:'La limite du nombre d\'appareils est atteinte',
                t215:'Un certificat est donné à la fin de la formation',
                t216:'Validation des données dans Excel',
                t217:'Améliorez vos compétences en analyse de données avec notre cours complet d’Excel intermédiaire et avancé. Parfait pour les professionnels souhaitant devenir des experts d’Excel.',
                t218:'Inscrivez-vous maintenant',
                t219:'Contactez-nous',
                t220:'À qui s’adresse ce cours ?',
                t221:'Ce cours est conçu pour les personnes souhaitant améliorer leurs connaissances de base en Excel et développer leurs compétences. Il est parfait pour :',
                t222:'Les professionnels cherchant à améliorer leurs capacités d’analyse de données',
                t223:'Les étudiants voulant se démarquer sur le marché du travail',
                t224:'Les utilisateurs d’Excel qui veulent aller au-delà des formules et fonctions de base',
                t225:'Toute personne souhaitant maîtriser les techniques intermédiaires à avancées d’Excel',
                t226:'Programme du cours',
                t227:'Découvrir la validation de base',
                t228:'Détecter et personnaliser les validations',
                t229:'Listes déroulantes dans Excel',
                t230:'Rendre les validations plus professionnelles',
                t231:'Ce que disent nos étudiants',
                t232:'Ce cours a transformé ma façon de travailler avec les données. Je suis maintenant beaucoup plus efficace et confiant dans mes compétences Excel.',
                t233:'Les techniques de formules avancées que j’ai apprises m’ont fait gagner des heures de travail chaque semaine. Hautement recommandé !',
                t234:'L’expertise de l’instructeur et ses explications claires ont rendu les concepts complexes faciles à comprendre et à appliquer.',
                t235:'Une fois inscrit, vous avez un accès à vie aux supports de cours, y compris les mises à jour futures.',
                t236:'Oui, nous offrons une garantie de remboursement de 15 jours. Si vous n’êtes pas satisfait du cours, vous pouvez demander un remboursement complet dans les 15 jours suivant l’achat.',
                t237:'Ce cours est conçu pour ceux qui ont des connaissances de base en Excel et cherchent à progresser. Une familiarité avec les fonctions et formules de base est recommandée.',
                t238:'Le cours est dispensé en ligne via notre plateforme d’apprentissage. Vous pouvez accéder aux leçons vidéo, exercices et ressources à votre propre rythme.',
                t239:'Responsable marketing',
                t240:'Analyste financier',
                t241:'Scientifique des données',
                t242:'Combien de temps ai-je accès au cours ?',
                t243:'Y a-t-il une garantie de remboursement ?',
                t244:'Ai-je besoin d’une expérience préalable ?',
                t245:'Comment le cours est-il dispensé ?',
                t246:'Tableaux Croisés Dynamiques & Graphiques',
                t247:'Exploitez tout le potentiel d\'Excel avec notre cours complet sur les tableaux croisés dynamiques et les graphiques. Transformez les données brutes en informations puissantes et en visualisations époustouflantes.',
                t248:'La formation dure environ 2 heures et demi, réparties sur plusieurs modules accessibles à votre rythme.',
                t248_:'La formation dure environ 2 heures, réparties sur plusieurs modules accessibles à votre rythme.',
                t249:'Quelle version d\'Excel aurais-je besoin pour ce cours ?',
                t250:'Toute version d\'Excel convient normalement, mais toute version supérieure à Excel 2013 est recommandée',
                t251:'Introduction aux tableaux croisés dynamiques',
                t252:'Organisation des données pour les tableaux croisés dynamiques',
                t253:'Techniques avancées de tableaux croisés dynamiques',
                t254:'Champs et éléments calculés',
                t255:'Mise en forme et disposition des tableaux croisés dynamiques',
                t256:'Défis et Solutions',
                t257:'Fondamentaux des graphiques dans Excel',
                t258:'Types de graphiques avancés et personnalisation',
                t259:'Graphiques dynamiques avec des formules',
                t260:'Meilleures pratiques de visualisation de données',
                t261:'Tableaux de bord interactifs avec graphiques',
                t262:'Intégration de Power BI pour des visuels avancés',
                t263:'Tableaux croisés dynamiques et graphiques',
                t264:'Déverrouillez le plein potentiel d’Excel avec notre cours complet sur les tableaux croisés dynamiques et graphiques. Transformez des données brutes en analyses puissantes et visualisations époustouflantes.',
                t265:'Ce que vous allez maîtriser',
                t266:'Tableaux croisés dynamiques',
                t267:'Apprenez à résumer, analyser et présenter des données complexes en toute simplicité.',
                t268:'Graphiques dynamiques',
                t269:'Créez des visualisations convaincantes qui donnent vie à vos données.',
                t270:'Modélisation des données',
                t271:'Construisez des modèles de données robustes pour des analyses et des rapports complexes.',
                t272:'Analyse des tendances',
                t273:'Identifiez et visualisez les tendances pour prendre des décisions basées sur les données.',
                t274:'Pourquoi ce cours précisément ?',
                t275:'Les tableaux croisés dynamiques et graphiques dans Excel sont des outils essentiels pour les entreprises afin d’analyser et de visualiser efficacement de grands ensembles de données. Les tableaux croisés dynamiques permettent aux utilisateurs de résumer, organiser et filtrer des informations complexes, facilitant ainsi l’identification des tendances, des schémas et des anomalies. Les graphiques complètent cela en transformant des données brutes en représentations visuelles, aidant ainsi les décideurs à comprendre les informations en un coup d\'œil. Dans des scénarios réels, les entreprises s’appuient sur ces outils pour des tâches telles que le reporting des ventes, l’analyse financière et la gestion des stocks, permettant ainsi des décisions plus rapides et basées sur les données, améliorant ainsi la productivité et les performances.',
                t276:'Pourquoi les tableaux croisés dynamiques ?',
                t277:'Résumez rapidement de grands ensembles de données avec une simplicité glisser-déposer',
                t278:'Analysez les données sous plusieurs angles en quelques clics',
                t279:'Créez des calculs et des groupements personnalisés pour des analyses plus approfondies',
                t280:'Générez des rapports en quelques minutes au lieu de quelques heures',
                t281:'Ce que vous apprendrez',
                t282:'Pourquoi les graphiques dans Excel ?',
                t283:'Transformez des données complexes en visuels clairs et convaincants',
                t284:'Personnalisez les graphiques pour qu’ils correspondent parfaitement à votre récit de données',
                t285:'Créez des tableaux de bord interactifs pour une exploration dynamique des données',
                t286:'Maîtrisez des techniques avancées pour des présentations percutantes',
                t287:'Ce que vous apprendrez',
                t288:'Maîtrise des tableaux croisés dynamiques',
                t289:'Expertise en graphiques',
                t290:'Prêt à transformer vos compétences sur Excel ?',
                t291:'Rejoignez des milliers de professionnels qui ont accéléré leur carrière grâce à notre cours complet de maîtrise d’Excel.',
                t292:'Inscrivez-vous maintenant et économisez 40%',
                t293:'Maîtrisez ',
                t294:'Les TCD',
                t295:'Créez des ',
                t296:'graphiques impressionnants',
                t297:'Programme du ',
                t298:'cours',
                t299:'Commencez maintenant',
                t300:'Voir le programme du cours',
                t301:'',
                t302:'',
                t303:'',
                t304:'',
                t305:'',
                t306:'',
                t307:'',
                t308:'',
                t309:'',
                t310:'',
                t311:'',
                t312:'',
                t313:'',
                t314:'',
                t315:'',
            }
        },
    },
    lng:localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr',
})
export default i18next