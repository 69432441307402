import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as c from '../components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDisclosure, useToast } from '@chakra-ui/react'
import Avatar from 'react-avatar-edit'
import * as a from '../backend/actions'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react'
import { checkForTitleTranslation } from '.'
// import { BarChart,Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend } from 'recharts'
// const data = [{name: 'Mon',Minutes: 150,},{name: 'Tue',Minutes: 10,},{name: 'Wed',Minutes: 40,},{name: 'Thu',Minutes: 120,},{name: 'Fri',Minutes: 50,},{name: 'Sat',Minutes: 60,},{name: 'Sun',Minutes: 78,}]

export default function User() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useNavigate()
    const [src, setSrc] = useState(null)
    var pfp = null
    const { loading: loading1, success, error: error1 } = useSelector(state => state.login)
    const { loading: loading2, userInfo, error: error2 } = useSelector(state => state.userDetails)
    const { loading: loading3, uC, fC } = useSelector(state => state.userCourses)
    const queryParams = new URLSearchParams(location.search)
    function onCloseX(){
        setSrc(null)
    }
    function onCrop(view){
        pfp = view
    }
    function splitter(str) {
        if (str) {
            return str.split(' ')[0]
        }
        return ''
    }
    function logout(){
        history('/')
        setTimeout(() => {
            dispatch(a.logout())
        }, 200)
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
        if (!success || error1) {
            if (error2) {
                history('/signin?mssg=expired')
            } else {
                history('/signin?mssg=login')
            }
        } else {
            dispatch(a.getUserDetails())
            dispatch(a.getUserCourses())
        }
        if (queryParams.get('mssg') === 'done1') {
            toast({
                title: t('t97'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'done2') {
            dispatch({type:'CHANGE_PASS_REQ'})
            toast({
                title: t('t103'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'done3') {
            dispatch({type:'CHANGE_PASS_REQ'})
            toast({
                title:t('t107'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'error1') {
            dispatch({type:'CHANGE_PASS_REQ'})
            toast({
                title:t('t108'),
                status: 'error',
                isClosable: true,
                duration: 2000
            })
        }
        // eslint-disable-next-line
    },[])

    if(userInfo === undefined && error2 ){
        history('/signin?mssg=expired')
        dispatch({type:'USER_REQ'})
        return <></>
    }else if(userInfo === undefined && !success ){
        history('/signin?mssg=login')
        return <></>
    }
    else if (
        loading1 ||
        loading2 ||
        loading3
    ) {
        return <c.Loading />
    }
    else if(userInfo){
    return (
        <div className='userDashboard'>
            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                closeOnOverlayClick={false}
                motionPreset='slideInBottom'
                variant='gray'
                size='100'
            >
                <ModalOverlay />
                <ModalContent width={800}>
                    <ModalHeader background='var(--bg)'>
                        <h3 style={{color:'var(--fg)'}}>{t('t109')}</h3>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody background='var(--bg)' overflowX='hidden'>
                        <Avatar
                            width='100%'
                            height={400}
                            minCropRadius={150}
                            onCrop={onCrop}
                            onClose={onCloseX}
                            src={src}
                            cropRadius={5}
                            label={t('t110')}
                            labelStyle={{
                                color:'var(--fg)',
                                fontSize:22,
                                width:'100%',
                                cursor:'pointer'
                            }}
                        />
                    </ModalBody>
                    <ModalFooter background='var(--bg)'>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>
                            {t('t111')}
                        </Button>
                        <Button 
                            colorScheme='green'
                            mr={3}
                            onClick={()=>{
                                if(pfp){
                                    a.uploadPhoto(pfp,userInfo._id)
                                }
                            }}
                        >
                            {t('t112')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <div style={{ height: 80 }} />
            <div className='welcome' data-aos='fade-up'>
                <img src={'/profilePhotos/' + userInfo.profilePath} alt='' />
                <h1>{t('t60')}, {splitter(userInfo.fullName)} !</h1>
                <div className='changePro' onClick={onOpen}>
                    <c.Icon type='square-pen' width={30} color='#FFF' />
                </div>
                <div className='logout' onClick={logout}>
                    <c.Icon type='power-off' width={30} color='#FFF' />
                </div>
            </div>
            <div className='courses' data-aos='zoom-in-up'>
                <h2>{t('t61')}</h2>
                <div className='content'>
                    {uC.map((k, i) => <c.CourseCard2
                        delay={`${i * 200 + 500}`}
                        key={i}
                        thumb={`/coursesFolder/${k.folderId}/thumb.jpg`}
                        title={checkForTitleTranslation(k.title,t)}
                        courseId={k.id}
                    />)}
                    {!userInfo.attributedCourses.length && <div className='viewNewCourse'>
                        <c.MyButton title={t('t92')} icon='chevron-right' link='/courses' />
                    </div>}
                </div>
            </div>
            <div className='courses' data-aos='zoom-in-up'>
                <h2>{t('t91')}</h2>
                <div className='content'>
                    {fC.map((k, i) => <c.CourseCard1
                        delay={`${i * 200 + 500}`}
                        key={i}
                        thumb={`/coursesFolder/${k.folderId}/thumb.jpg`}
                        title={checkForTitleTranslation(k.title,t)}
                        courseId={k.id}
                        userId={userInfo._id}
                        his={history}
                        loc={location}
                    />)}
                    {!userInfo.favCourses.length && <div className='viewNewCourse'>
                        <c.MyButton title={t('t92')} icon='chevron-right' link='/courses' />
                    </div>}
                </div>
            </div>
            {/* <div className='progressDashboard' data-aos='zoom-in-up'>
                <h2>{t('t62')}</h2>
                <div className='content'>
                    <div className='graph'>
                        <BarChart
                            width={window.innerWidth <= 700 ? 0.95 * window.innerWidth - 75 : 500}
                            height={300}
                            data={data}
                            barSize={20}
                        >
                            <XAxis dataKey='name' scale='point' padding={{ left: 10, right: 10 }} />
                            <YAxis />
                            <Tooltip contentStyle={{ background: 'var(--color1)', border: 'none' }} itemStyle={{ color: '#fff' }} />
                            <Legend />
                            <CartesianGrid strokeDasharray='3 3' />
                            <Bar dataKey='Minutes' fill='var(--color1)' background={{ fill: '#eee' }} />
                        </BarChart>
                    </div>
                    <div className='statistics'>
                        <div className='staticRow'>
                            <div className='static n' data-aos='flip-left'>
                                <c.Icon type='clock' />
                                <h1>45min</h1>
                                <h3>{t('t63')}</h3>
                            </div>
                            <div className='static n' data-aos='flip-left'>
                                <c.Icon type='check' />
                                <h1>38%</h1>
                                <h3>{t('t64')}</h3>
                            </div>
                        </div>
                        <div className='staticRow'>
                            <div className='static n' data-aos='flip-left'>
                                <c.Icon type='circle-check' />
                                <h1>0</h1>
                                <h3>{t('t65')}</h3>
                            </div>
                            <div className='static n' data-aos='flip-left'>
                                <c.Icon type='fire-flame-curved' />
                                <h1>7</h1>
                                <h3>{t('t66')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alignBottom">
                    <c.MyButton title={t('t101')} icon='chevron-right' link='/courses' />
                </div>
            </div> */}
            <div className='infos' data-aos='zoom-in-up'>
                <h2>{t('t67')}</h2>
                <div className='rowInfo'>
                    <div className='info X' data-aos='fade-up'>
                        <p>{t('t68')}</p>
                        <div className='y'>
                            <h4 className='t'>{userInfo.fullName}</h4>
                        </div>
                    </div>
                    <div className='info X' data-aos='fade-up'>
                        <p>{t('t69')}</p>
                        <div className='y'>
                            <h4 className='t'>{userInfo.email}</h4>
                        </div>
                    </div>
                </div>
                <div className='rowInfo'>
                    <div className='info X' data-aos='fade-up'>
                        <p>{t('t70')}</p>
                        <div className='y'>
                            <h4 className='t'>{userInfo.phoneNumber}</h4>
                        </div>
                    </div>
                    <div className='info X' data-aos='fade-up'>
                        <p>{t('t71')}</p>
                        <div className='y'>
                            <c.MyButton title={t('t72')} type={1} link='/change-password' icon='arrows-rotate' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='logOut'>
                <button onClick={logout}>
                    <h3>{t('t90')}</h3>
                </button>
            </div>
            {userInfo.isAdmin && <div className='logOut'>
                <button onClick={()=>{history('/admin')}}>
                    <h3>{t('t128')}</h3>
                </button>
            </div>}
        </div>
    )}
}