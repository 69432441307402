import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as c from '../components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as a from '../backend/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { icon5 } from '../img'
export default function Preview() {
    const videoRef = useRef(null)
    const toast = useToast()
    const dispatch = useDispatch()
    const { loading:loading1, course } = useSelector(state => state.course)
    const { loading:loading2, comments } = useSelector(state => state.getComments)
    const { id: userId } = useSelector(state => state.login)
    const { t } = useTranslation()
    const { id } = useParams()
    const history = useNavigate()
    const [rating, setRating] = useState(1)
    const [commentBody, setCommentBody] = useState('')
    const [commentError, setCommentError] = useState('')
    const [videoTitle, setVideoTitle] = useState('')
    const [videoDuration, setVideoDuration] = useState('0:00')
    const [courseVideos, setCourseVideos] = useState([])
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    function formatDuration(sec) {
        let min = Math.floor(sec / 60);
        let rsec = sec % 60;
        if(rsec < 10){rsec = '0' + rsec}
        if(min < 10){min = '0' + min}
        return min + ':' + rsec;
    }
    function transformDate(str) {
        if(str){
            const date = new Date(str)
            const month = date.getMonth() + 1
            const year = date.getFullYear()
            return `${month.toString().padStart(2, '0')}/${year}`
        }
        return ''
    }
    function addFavCourse() {
        if (!userId) {
            history('/signin?mssg=login')
        } else {
            a.addUserFavCourse(userId, id, history, location)
        }
    }
    function buyCourse() {
        if (!userId) {
            history('/signin?mssg=login')
        } else {
            history('/buy-course/'+course._id)
        }
    }
    function submitComment(){
        if(commentBody === ''){
            setCommentError('Please fill out the field')
        }else{
            if (!userId) {
                history('/signin?mssg=login')
            } else {
                setCommentError('')
                const form = {
                    userId,
                    rating,
                    body:commentBody,
                    courseId:course._id
                }
                dispatch(a.postComment(form))
                history(location.pathname+'?mssg=done1')
                setCommentBody('')
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
        dispatch(a.getCourse(id))
        dispatch(a.getComment(id))
        dispatch(a.getCourse1stVideo(id,setVideoTitle,setVideoDuration,formatDuration,setCourseVideos))
        if(userId){
            dispatch(a.getUserBuyRequests())
        }
        if(course.hasLandingPage){
            history('/page-not-found')
        }
        if (queryParams.get('mssg') === 'done') {
            toast({
                title: t('t97'),
                description: t('t98'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'already_exists') {
            toast({
                title: t('t99'),
                description: t('t100'),
                status: 'warning',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'done1') {
            toast({
                title: t('t97'),
                description: t('t113'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'error') {
            toast({
                title: t('t159'),
                description: t('t160'),
                status: 'error',
                isClosable: true,
                duration: 2000
            })
        }
        // eslint-disable-next-line
    }, [dispatch, id, history, location, toast])
    if (loading1 || loading2 || !course || !comments) {
        return <c.Loading />
    }
    return (
        <>
            <button className='sticky-button' onClick={buyCourse} id='buyCourse'>
                <c.Icon type='bag-shopping' id='buyCourse'/>
                {t('t54')}
            </button>
            <div style={{ height: 80 }} />
            <div className='topBanner2'>
                <div className='description'>
                    <div className='tags'>
                        {course.tags.map((tag, i) => <div className='tag n' key={i}>{tag}</div>)}
                    </div>
                    <div className='title'>
                        <h1>{course.title}</h1>
                        <p>{course.littleDescription}</p>
                    </div>
                    <div className='info1'>
                        {course.bestSeller && <div className='tagX'>{t('t51')}</div>}
                        <div className='rating'>
                            <p>{course.rating}</p>
                            <c.Rating n={course.rating} />
                        </div>
                    </div>
                    <div className='info2'>
                        <p>{t('t52')}<span>{course.author}</span>,</p>
                        <p>{transformDate(course.createdAt)}</p>
                    </div>
                </div>
                <div className='thumbnail'>
                    <img src={`/coursesFolder/${course.folderId}/thumb.jpg`} alt='' />
                </div>
                <div className='la'>
                    <button className='bttn expanded' onClick={addFavCourse}>
                        <h4>{t('t53')}</h4>
                        <c.Icon type='chevron-left' width={20} />
                    </button>
                    <button className='bttn expanded k' onClick={buyCourse} id='buyCourse'>
                        {t('t54')}
                        <c.Icon type='chevron-right' width={20} id='buyCourse' />
                    </button>
                </div>
            </div>
            <div className='video1st'>
                <c.VideoPlayer
                    videoRef={videoRef}
                    videoX={`/coursesFolder/${course.folderId}/${course.folderId}001.mp4`}
                    title={videoTitle}
                    duration={videoDuration}
                    userMetMin={()=>{}}
                    next={()=>{}}
                />
            </div>
            <h2 className='categoryTitle'>{t('t55')}</h2>
            <div className='viewCourseContent'>
                <p>{course.toWhomThisCourse}</p>
            </div>
            <h2 className='categoryTitle'>{t('t56')}</h2>
            <div className='viewCourseContent x y'>
                <ul>
                    {course.acquisitions.map((a, i) => <li key={i}>{a}</li>)}
                </ul>
            </div>
            <h2 className='categoryTitle'>{t('t57')}</h2>
            <div className='viewCourseContent x'>
                <ul>
                    {course.prerequisites.map((a, i) => <li key={i}>{a}</li>)}
                </ul>
            </div>
            <h2 className='categoryTitle'>{t('t58')}</h2>
            <div className='viewCourseContent'>
                <p>{course.description}</p>
            </div>
            <div className="buyThis">
                <button className='bttn expanded' onClick={buyCourse}>
                    <h4>{t('t54')}</h4>
                    <c.Icon type='chevron-right' width={20} />
                </button>
            </div>
            <h2 className='categoryTitle'>{t('t158')} :</h2>
            <div className='viewCourseContent w'>
                {courseVideos.map(k=><div className='rowVideo' key={k.title}>
                    <img src={icon5} alt='' />
                    <p>{k.title}</p>
                    <p>{formatDuration(k.duration)}</p>
                </div>)}
            </div>
            <h2 className='categoryTitle'>{t('t59')}</h2>
            <div className='viewCourseContent x z'>
                <div className='newComment'>
                    <c.RatingComment rating={rating} setRating={setRating} />
                    <textarea type='text' placeholder={t('t114')} value={commentBody} onChange={(e)=>{setCommentBody(e.target.value)}}/>
                    {commentError && <div className='error'>
                        <c.Icon type='xmark' />
                        <p>{commentError}</p>
                    </div>}
                    <c.MyButton isMinimal='btn' title={t('t115')} icon='circle-chevron-down' href={submitComment}/>
                </div>
                {comments.map((comment,i) => <c.Comment key={i} username={comment.fullName} rating={comment.rating} pfp={comment.profile} body={comment.body} />)}
            </div>
            <div style={{ height: 100 }} />
        </>
    )
}
