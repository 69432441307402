import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { configureStore,combineReducers } from '@reduxjs/toolkit'
import * as r from './reducers'
const persistConfig = {
  key:'root',
  version:1,
  storage
}
const reducerA = combineReducers({
  login:r.login,
  signup:r.signup,
  userDetails:r.userDetails,
  courses:r.courses,
  course:r.course,
  userCourses:r.userCourses,
  changePassword:r.changePassword,
  courseVideos:r.courseVideos,
  course1stVideo:r.course1stVideo,
  postComment:r.postComment,
  getComments:r.getComments,
  getCourseProgress:r.getCourseProgress,
  getUsers:r.getUsers,
  buyRequest:r.buyRequest,
  getUserBuyRequests:r.getUserBuyRequests,
  getUsersBuyRequests:r.getUsersBuyRequests
})

const reducer = persistReducer(persistConfig,reducerA)
const store = configureStore({reducer})

export default store