export const SERVER_URL = 'https://alphalearner.ma/SikDdFnfGgfB54ghCXn5s4CXp/'
// export const SERVER_URL = 'http://localhost:8000/SikDdFnfGgfB54ghCXn5s4CXp/'
export const c1 = 'LOGIN_REQ'
export const c2 = 'LOGIN_SUCCESS'
export const c3 = 'LOGIN_FAIL'
export const c4 = 'USER_LOGOUT'
export const c5 = 'SIGNUP_REQ'
export const c5_ = 'SIGNUP_REQ1'
export const c6 = 'SIGNUP_SUCCESS0'
export const c6_ = 'SIGNUP_SUCCESS1'
export const c6__ = 'SIGNUP_SUCCESS2'
export const c6___ = 'SIGNUP_SUCCESS3'
export const c6____ = 'SIGNUP_SUCCESS4'
export const c7 = 'SIGNUP_FAIL'
export const c8 = 'USER_REQ'
export const c9 = 'USER_SUCCESS'
export const c10 = 'USER_FAIL'
export const c11 = 'COURSES_REQ'
export const c12 = 'COURSES_SUCCESS'
export const c13 = 'COURSES_FAIL'
export const c14 = 'COURSE_REQ'
export const c15 = 'COURSE_SUCCESS'
export const c16 = 'COURSE_FAIL'
export const c17 = 'USER_COURSES_REQ'
export const c18 = 'USER_COURSES_SUCCESS'
export const c19 = 'USER_COURSES_FAIL'
export const c20 = 'CHANGE_PASS_REQ'
export const c21 = 'CHANGE_PASS_SUCCESS'
export const c22 = 'CHANGE_PASS_FAIL'
export const c23 = 'VIDEO_REQ'
export const c24 = 'VIDEO_SUCCESS'
export const c25 = 'VIDEO_FAIL'
export const c23_ = 'VIDEO1_REQ'
export const c24_ = 'VIDEO1_SUCCESS'
export const c25_ = 'VIDEO1_FAIL'
export const c26 = 'POST_COMMENT_REQ'
export const c27 = 'POST_COMMENT_SUCCESS'
export const c28 = 'POST_COMMENT_FAIL'
export const c29 = 'GET_COMMENTS_REQ'
export const c30 = 'GET_COMMENTS_SUCCESS'
export const c31 = 'GET_COMMENTS_FAIL'
export const c32 = 'COURSE_PROGRESS_REQ'
export const c33 = 'COURSE_PROGRESS_SUCCESS'
export const c34 = 'COURSE_PROGRESS_FAIL'
export const c35 = 'GET_USERS_REQ'
export const c36 = 'GET_USERS_SUCCESS'
export const c37 = 'GET_USERS_FAIL'
export const c38 = 'BUY_REQ'
export const c39 = 'BUY_SUCCESS'
export const c40 = 'BUY_FAIL'
export const c41 = 'BUY_REQ_REQ'
export const c42 = 'BUY_REQ_SUCCESS'
export const c43 = 'BUY_REQ_FAIL'
export const c44 = 'BUY_REQ_ADMIN_REQ'
export const c45 = 'BUY_REQ_ADMIN_SUCCESS'
export const c46 = 'BUY_REQ_ADMIN_FAIL'