import * as imgs from '../img'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Icon} from '.'
function Footer() {
    const { t } = useTranslation()
    return (
        <footer className='footer-distributed'>
            <div className='footer-left'>
                <img src={imgs.logoW} alt='' />
                <p className='footer-links'>
                    <NavLink to='/' className='link-1'>{t('t1')}</NavLink>
                    <NavLink to='/courses'>{t('t2')}</NavLink>
                    <NavLink to='/contact'>{t('t3')}</NavLink>
                </p>
                <p className='footer-company-name'>Alpha Learner © 2024</p>
            </div>
            <div className='footer-center'>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-location-dot'></i></div>
                    <p><span>My Work Center, Moulay Youssef</span> Casablanca, {t('t6')}</p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-phone'></i></div>
                    <p><a href='tel:212694128708'>+2126.94.12.87.08</a></p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-envelope'></i></div>
                    <p><a href='mailto:contact@alphalearner.ma'>contact@alphalearner.ma</a></p>
                </div>
            </div>
            <div className='footer-right'>
                <p className='footer-company-about'>
                    <span>Alpha Learner</span>
                    {t('t7')}
                </p>
                <div className='footer-social'>
                    <span>Follow Us</span>
                    <div className='icons'>
                        <a className='iconContainer' href='https://www.instagram.com/alpha_learner.official/' rel='noreferrer' target='_blank'>
                            <Icon type='instagram' brand/>
                        </a>
                        <a className='iconContainer' href='https://www.linkedin.com/in/alpha-learner-aa02ba323/' rel='noreferrer' target='_blank'>
                            <Icon type='linkedin' brand/>
                        </a>
                        <a className='iconContainer' href='https://x.com/alpha_learner1' rel='noreferrer' target='_blank'>
                            <Icon type='twitter' brand/>
                        </a>
                        <a className='iconContainer' href='https://www.tiktok.com/@alpha_learner' rel='noreferrer' target='_blank'>
                            <Icon type='tiktok' brand/>
                        </a>
                        <a className='iconContainer' href='https://wa.me/message/A5ZNX4XRZTESE1' rel='noreferrer' target='_blank'>
                            <Icon type='whatsapp' brand/>
                        </a>
                        <a className='iconContainer' href='https://www.facebook.com/people/Alphalearner/61562975821177/' rel='noreferrer' target='_blank'>
                            <Icon type='facebook' brand/>
                        </a>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer