import { Rating, Icon } from '.';
import { useNavigate } from 'react-router-dom';
import { deleteUserFavCourse } from '../backend/actions';
export function CourseCard({ title, thumb, rating, id,customURL }) {
    const history = useNavigate()
    return (
        <div className='courseContainer n' data-aos='zoom-out' onClick={() => {history(customURL ? `/${customURL}/${id}` :'/view-course/' + id) }}>
            <div className='thumb'>
                <img src={thumb} alt='' />
            </div>
            <div className='details'>
                <div className='title'>
                    <h3>{title}</h3>
                </div>
                <div className='rating'>
                    <p>{rating}</p>
                    <div className='stars'>
                        <Rating n={rating} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export function CourseCard1({ thumb, title, delay,courseId, userId, his, loc }) {
    const history = useNavigate()
    return (
        <div className='courseContainer2' data-aos='zoom-in' data-aos-delay={delay}>
            <div className='deleteButton' onClick={() => { deleteUserFavCourse(userId, courseId, his, loc) }}>
                <Icon type='trash' width={22} />
            </div>
            <div className='thumb'>
                <img src={thumb} alt='' />
            </div>
            <h5 onClick={() => { history('/view-course/' + courseId) }}>{title}</h5>
        </div>
    )
}
export function CourseCard2({ thumb, percentage, title, delay, courseId }) {
    const history = useNavigate()
    return (
        <div className='courseContainer2' data-aos='zoom-in' data-aos-delay={delay}>
            <div className='thumb'>
                {percentage && <div className='percentage n'>
                    <h3>{percentage}%</h3>
                </div>}
                <img src={thumb} alt='' />
            </div>
            <h5 onClick={() => { history('/course/' + courseId) }}>{title}</h5>
        </div>
    )
}