import { Button } from '@chakra-ui/react'
import { Icon } from '.'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { confirmReq, confirmReqNoUser, deleteUserCourse } from '../backend/actions'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon
} from '@chakra-ui/react'
import { checkForTitleTranslation } from '../pages'
function reduceStringLength(str){
    if(str.length < 25){return str}
    return str.slice(0,25)+'...'
}
function transformDate(z) {
    const options = {
        timeZone: 'Africa/Casablanca',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
      };
    if(z){
        const x = new Date(z)
        const formatter = new Intl.DateTimeFormat('en-GB', options)
        const formattedDate = formatter.format(x)
        return formattedDate.replace(', ', ' - ').slice(0,-7)
    }
    return '-'
}
export function ModalCourses({isOpen,onClose,addCourse,name,courses,attCourses,err,userId,setSelectedOption,selectedOption}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader background='var(--bg)'>
                <h3 style={{color:'var(--fg)'}}>{name}</h3>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody background='var(--bg)'>
                <div className='coursesAtt'>
                    {attCourses.map((course)=> <div className='co-Att' key={course.id}>
                        <div className='titleY'>
                            <Icon type='lines-leaning' />
                            <h3>{reduceStringLength(checkForTitleTranslation(course.title,t))}</h3>
                        </div>
                        <div className='deleteBtn'>
                            <p>{(course.progress*100).toFixed(1)}%</p>
                            <Icon type='trash' onClick={()=>{dispatch(deleteUserCourse(userId,course.id))}}/>
                        </div>
                    </div>
                    )}
                </div>
                <div className='coursesDrop'>
                    <select value={selectedOption} onChange={e => {setSelectedOption(e.target.value)}}>
                        <option value=''>Select a course</option>
                        {courses.map(course => <option value={course.id} key={course.id}>{course.title}</option>)}
                    </select>
                </div>
                {err && <div className='error'>
                    <Icon type='xmark' />
                    <p>{err}</p>
                </div>}
            </ModalBody>
            <ModalFooter background='var(--bg)'>
                <Button colorScheme='gray' mr={3} onClick={onClose}>
                    {t('t131')}
                </Button>
                <Button 
                    colorScheme='green'
                    mr={3}
                    onClick={addCourse}
                >
                    {t('t132')}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}
export function ModalPurchases({isOpen,onClose,name,buyRequests}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const states = {
        pending:t('t151'),
        approved:t('t152'),
        denied:t('t153')
    }
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader background='var(--bg)'>
                <h3 style={{color:'var(--fg)'}}>{name}</h3>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody background='var(--bg)'>
                <div className='buyRequests'>
                    <Accordion>
                        {buyRequests.map(req => <AccordionItem key={req.id}>
                            <AccordionButton>
                                <div className='accordionHeader'>
                                    <img src={`/coursesFolder/${req.course.folderId}/thumb.jpg`} alt='' />
                                    <h6>{checkForTitleTranslation(req.course.title,t)}</h6>
                                    <AccordionIcon />
                                </div>
                            </AccordionButton>
                            <AccordionPanel>
                                <div className='accordionBody'>
                                    <div className='infoRow'>
                                        <p>{t('t12')} :</p>
                                        <p>{req.fullName}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t48')} :</p>
                                        <p>{req.phoneNumber}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t13')} :</p>
                                        <p>{req.email}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t147')} :</p>
                                        <p>{req.city}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t148')} :</p>
                                        <p>{req.level}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t149')} :</p>
                                        <p>{req.course.price} MAD</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t150')} :</p>
                                        <p>{states[req.state]}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t210')} :</p>
                                        <p>{transformDate(req.createdAt)}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t211')} :</p>
                                        <p>{transformDate(req.confirmedAt)}</p>
                                    </div>
                                </div>
                                {req.state === 'pending' && <div className='accordionFooter'>
                                    <Icon type='xmark' onClick={()=>{dispatch(confirmReq({
                                        buyRequestId:req._id,
                                        state:'denied'
                                    }))}}/>
                                    <Icon type='check' onClick={()=>{dispatch(confirmReq({
                                        buyRequestId:req._id,
                                        state:'approved',
                                        courseTitle:checkForTitleTranslation(req.course.title,t),
                                        courseDescription:checkForTitleTranslation(req.course.littleDescription,t),
                                    }))}}/>
                                </div>}
                            </AccordionPanel>
                        </AccordionItem>
                        )}
                    </Accordion>
                </div>
            </ModalBody>
            <ModalFooter background='var(--bg)'>
                <Button colorScheme='gray' mr={3} onClick={onClose}>
                    {t('t131')}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}
export function ModalPurchase({isOpen,onClose,req}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const states = {
        pending:t('t151'),
        approved:t('t152'),
        denied:t('t153')
    }
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader background='var(--bg)'>
                <h3 style={{color:'var(--fg)'}}>Request</h3>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody background='var(--bg)'>
                <div className='buyRequests'>
                    <Accordion>
                        <AccordionItem>
                            <AccordionButton>
                                <div className='accordionHeader'>
                                    <img src={`/coursesFolder/${req.course.folderId}/thumb.jpg`} alt='' />
                                    <h6>{checkForTitleTranslation(req.course.title,t)}</h6>
                                    <AccordionIcon />
                                </div>
                            </AccordionButton>
                            <AccordionPanel>
                                <div className='accordionBody'>
                                    <div className='infoRow'>
                                        <p>{t('t12')} :</p>
                                        <p>{req.fullName}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t48')} :</p>
                                        <p>{req.phoneNumber}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t13')} :</p>
                                        <p>{req.email}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t147')} :</p>
                                        <p>{req.city}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t148')} :</p>
                                        <p>{req.level}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t149')} :</p>
                                        <p>{req.course.price} MAD</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t150')} :</p>
                                        <p>{states[req.state]}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t210')} :</p>
                                        <p>{transformDate(req.createdAt)}</p>
                                    </div>
                                    <div className='infoRow'>
                                        <p>{t('t211')} :</p>
                                        <p>{transformDate(req.confirmedAt)}</p>
                                    </div>
                                </div>
                                {req.state === 'pending' && <div className='accordionFooter'>
                                    <Icon type='xmark' onClick={()=>{dispatch(confirmReqNoUser({
                                        buyRequestId:req._id,
                                        state:'denied'
                                    }))}}/>
                                    <Icon type='check' onClick={()=>{dispatch(confirmReqNoUser({
                                        courseTitle:checkForTitleTranslation(req.course.title,t),
                                        courseDescription:checkForTitleTranslation(req.course.littleDescription,t),
                                        buyRequestId:req._id,
                                        state:'approved'
                                        }))}}/>
                                </div>}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </ModalBody>
            <ModalFooter background='var(--bg)'>
                <Button colorScheme='gray' mr={3} onClick={onClose}>
                    {t('t131')}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}