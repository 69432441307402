import { useTranslation } from 'react-i18next';
import {InputDiv,Icon, Input} from '.';
import { useSelector } from 'react-redux';
import { illu3, illu4 } from '../img';

function emailHidden(str){
	if(str){
		const k = str.split('@')
		const fLet = k[0][0]
		const lLet = k[0][k[0].length-1]
		return fLet+'*******'+lLet+'@'+k[1]
	}else{
		return ''
	}
}
function numberHidden(num){
	const a = num.slice(0,num.length > 10 ? 5 : 2)
	const b = num.slice(-2)
	return a+'*'.repeat(num.length-(num.length > 10 ? 5 : 2))+b
}

var errorsDic
export function Welcome() {
	const {t} = useTranslation()
	errorsDic = {
		ERR00002: t('t85'),
		ERR00003: t('t117'),
		ERR00007: t('t86'),
		ERR00010: t('t87'),
		ERR000121:t('t213'),
		ERR000122:t('t213'),
	}
	const { error } =  useSelector(state => state.signup)
	return (
		<div className='multiform mf-1'>
			<h1 className='title'>{t('t118')}</h1>
			<div className='body'>
				<div className='inputs'>
					<div className='inputRow'>
						<InputDiv icon='user' placeholder={t('t12')} name='fullName'/>
						<InputDiv icon='envelope' placeholder={t('t13')} name='email' />
					</div>
				</div>
				{error && <div className='error'>
					<Icon type='xmark' />
					<p>{errorsDic[error]}</p>
				</div>}
			</div>
		</div>
	)
}
export function EmailVer({code,setCode}) {
	const {t} = useTranslation()
	const {email,error} = useSelector(state=>state.signup)
	errorsDic = {
		ERR00002: t('t85'),
		ERR00003: t('t117'),
		ERR00007: t('t86'),
		ERR00010: t('t87'),
		ERR000121:t('t135'),
		ERR000122:t('t135'),
	}
	return (
		<div className='multiform mf-2'>
			<h1 className='title'>{t('t119')}</h1>
			<div className='body'>
				<img src={illu3} alt=''/>
				<div className='instructions'>
					<h6>{t('t127')} {emailHidden(email ? email : localStorage.getItem('emailSignUp'))}</h6>
					<div className='theInput x'>
						<div className='iconLabel'>
							<Icon type='lock' />
						</div>
						<input className='entryInput' placeholder={t('t136')} name='emailVerification' value={code} onChange={(e)=>{setCode(e.target.value)}}/>
					</div>
					{error && <div className='error'>
						<Icon type='xmark' />
						<p>{errorsDic[error]}</p>
					</div>}
				</div>
			</div>
		</div>
	)
}
export function Password() {
	const {t} = useTranslation()
	return (
		<div className='multiform mf-3'>
			<h1 className='title'>{t('t120')}</h1>
			<div className='body'>
				<div className='inputs'>
					<div className='inputRow'>
						<InputDiv type='password' placeholder={t('t14')} name='password'/>
						<InputDiv type='password' placeholder={t('t15')} name='confirmPassword' />
					</div>
				</div>
			</div>
		</div>
	)
}
export function Phone() {
	const {t} = useTranslation()
	const { error } =  useSelector(state => state.signup)
	return (
		<div className='multiform mf-4'>
			<h1 className='title'>{t('t121')}</h1>
			<div className='body'>
				<div className='inputs'>
					<div className='inputRow'>
						<InputDiv icon='phone' placeholder={t('t48')} name='phoneNumber'/>
					</div>
				</div>
				{error && <div className='error'>
					<Icon type='xmark' />
					<p>{errorsDic[error]}</p>
				</div>}
			</div>
		</div>
	)
}
export function PhoneVer() {
	const {t} = useTranslation()
	return (
		<div className='multiform mf-5'>
			<h1 className='title'>{t('t122')}</h1>
			<div className='body'>
			<img src={illu4} alt=''/>
				<div className='instructions'>
					<p>Enter the code sent to {numberHidden('0661576419')}</p>
					<Input type='number' icon='lock' name='emailVerification' inputDescription={t('t136')}/>
				</div>
			</div>
		</div>
	)
}
export function Done() {
	const {t} = useTranslation()
	return (
		<div className='multiform mf-6'>
			<h1 className='title'>{t('t123')}</h1>
			<div className='body'>
				<h2>{t('t124')}<br />{t('t125')}<span>{t('t28')}</span></h2>
				<p>{t('t126')}</p>
			</div>
		</div>
	)
}