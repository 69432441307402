import {Icon} from '.'

export default function StatCard({bg,icon,title,value}) {
    return (
        <div className='statCard n' style={{background:bg}}>
            <div className='icon'>
                <Icon type={icon} />
            </div>
            <div className='content'>
                <h6>{title}</h6>
                <h4>{value}</h4>
            </div>
        </div>
    )
}
