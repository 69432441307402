import { useTranslation } from 'react-i18next'
import { Icon, Loading, MyButton } from '../components'
import * as i from '../img'
import { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCourse } from '../backend/actions'

export function ExcelCourse() {	
    const history = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {loading, course} = useSelector(state=> state.course)
    const {id} = useParams()
    useEffect(() => {
        dispatch(getCourse(id))
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
        // eslint-disable-next-line
    },[dispatch])
    function buyCourse() {
        history('/buy-course/'+course._id)
    }
    if(loading || !course){
        return <Loading />
    }
    return (
        <div className='landingExcel'>
            <div style={{height:80}} />
            <header className='header'>
                <div className='content'>
                    <h1>{t('t161')}</h1>
                    <p>{t('t162')}</p>
                    <div className='certificate'>
                        <img src={i.icon8} alt='' />
                        <p>{t('t215')}</p>
                    </div>
                    <button className='cta-button' onClick={buyCourse}>{t('t163')}</button>
                </div>
                <div className='thumb'>
                    <img src={`/coursesFolder/${course.folderId}/thumb.jpg`} alt='miniature' />
                </div>
            </header>
            <section className='intro'>
                <div className='thumb'>
                    <img src={i.img8} alt='Personne utilisant Excel' />
                </div>
                <div className='content'>
                    <h2>{t('t164')}</h2>
                    <p>{t('t165')}</p>
                    <ul>
                        <li>{t('t166')}</li>
                        <li>{t('t167')}</li>
                        <li>{t('t168')}</li>
                    </ul>
                </div>
            </section>
            <div className='sectionTitle t'>
                <h1>{t('t199')}<span>{t('t200')}</span>{t('t201')}</h1>
                <div className='decoration'>
                    <div className='x'></div>
                </div>
            </div>
            <section className='curriculum'>
                <div className='thumb'>
                    <img src={i.img10} alt='module'/>
                </div>
                <div className='content'>
                    <ul>
                        <li>Module 1 : {t('t202')}</li>
                        <li>Module 2 : {t('t203')}</li>
                        <li>Module 3 : {t('t204')}</li>
                        <li>Module 4 : {t('t205')}</li>
                        <li>Module 5 : {t('t206')}</li>
                        <li>Module 6 : {t('t207')}</li>
                        <li>Module 7 : {t('t208')}</li>
                    </ul>
                    <button className='cta-button x' onClick={buyCourse}>{t('t198')}</button>
                </div>
            </section>
            <div className='sectionTitle t'>
                <h1>{t('t193')}<span>{t('t194')}</span>{t('t195')}<span>{t('t196')}</span>{t('t197')}:</h1>
                <div className='decoration'>
                    <div className='x'></div>
                </div>
            </div>
            <div className='yourProgram'>
                <ul className='content'>
                    <li><Icon type='circle-check' width={25} />{t('t169')}</li>
                    <li><Icon type='circle-check' width={25} />{t('t170')}</li>
                    <li><Icon type='circle-check' width={25} />{t('t171')}</li>
                    <li><Icon type='circle-check' width={25} />{t('t172')}</li>
                </ul>
                <div className='thumb'>
                    <img src={i.img9} alt='' />
                </div>
            </div>
            <section className='cards'>
                <div className='infoCard n'>
                    <img src={i.img11} alt=''/>
                    <h2>{t('t173')}</h2>
                    <p>{t('t174')}</p>
                </div>
                <div className='infoCard n'>
                    <img src={i.img13} alt=''/>
                    <h2>{t('t175')}</h2>
                    <p>{t('t176')}</p>
                </div>
                <div className='infoCard n'>
                    <img src={i.img12} alt=''/>
                    <h2>{t('t177')}</h2>
                    <p>{t('t178')}</p>
                </div>
            </section>
            <section className='faq'>
                <div className='title'>
                    <img src={i.faq} alt='faq icon' />
                    <h2>{t('t179')}</h2>
                </div>
                <div className='content'>
                    <div className='questions'>
                        <div className='question'>
                            <h3>{t('t180')}</h3>
                            <h3>1</h3>
                        </div>
                        <p>{t('t181')}</p>
                        <div className='question'>
                            <h3>{t('t182')}</h3>
                            <h3>2</h3>
                        </div>
                        <p>{t('t183')}</p>
                        <div className='question'>
                            <h3>{t('t184')}</h3>
                            <h3>3</h3>
                        </div>
                        <p>{t('t185')}</p>
                    </div>
                    <div className='questionHelp'>
                        <img src={i.question} alt='' />
                        <h2>{t('t186')}</h2>
                        <p>{t('t187')}</p>
                        <div className='actions'>
                            <a href='mailto:contact@alphalearner.com' className='bttn'>
                                <Icon type='envelope' width={20} />
                                <h4>{t('t188')}</h4>
                            </a>
                            <a href='https://wa.me/212612345678' className='bttn'>
                                <Icon type='whatsapp' brand width={20} />
                                <h4>{t('t189')}</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <div className='startRightNow'>
                <h2>{t('t190')}</h2>
                <p>{t('t191')}</p>
                <MyButton title={t('t198')} icon='chevron-right' href={buyCourse} isMinimal='btn'/>
            </div>
            <footer className='footer'>
                <p>{t('t192')} : <a href='mailto:contact@alphalearner.ma'>contact@alphalearner.ma</a> | <a href='tel:212694128708'>+2126.94.12.87.08</a></p>
            </footer>
        </div>
    )
}

export function ExcelCourseDataValidation() {
	const {loading, course} = useSelector(state=> state.course)
	const dispatch = useDispatch()
    const history = useNavigate()
    const [activeFaq, setActiveFaq] = useState(null)
    const {id} = useParams()
	const {t} = useTranslation()
    function buyCourse() {
		dispatch(getCourse(id))
        history('/buy-course/'+id)
    }
    const toggleFaq = (index) => {
        setActiveFaq(activeFaq === index ? null : index)
    }
	useEffect(() => {
		dispatch(getCourse(id))
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
	},[dispatch])
	if(loading || !course){
        return <Loading />
    }
    return (
        <div className='landing-page'>
            <section className='hero'>
            <div className='container'>
                <div className='hero-content'>
                    <h1>{t('t216')}</h1>
                    <p>{t('t217')}</p>
                    <div className='certificate'>
                        <img src={i.icon8} alt='' />
                        <p>{t('t215')}</p>
                    </div>
                    <div className='cta-buttons'>
                        <button className='cta-button1' onClick={buyCourse}>{t('t218')}</button>
                        <button className='secondary-button' onClick={()=>{history('/contact')}}>{t('t219')}</button>
                    </div>
                </div>
                <div className='hero-image'>
                    <img src={`/coursesFolder/${course.folderId}/thumb.jpg`} alt='Excel' />
                </div>
            </div>
            </section>
            <section id='who-is-this-for' className='who-is-this-for'>
            <div className='container'>
                <h2>{t('t220')}</h2>
                <p>{t('t221')}</p>
                <ul>
                    <li>{t('t222')}</li>
                    <li>{t('t223')}</li>
                    <li>{t('t224')}</li>
                    <li>{t('t225')}</li>
                </ul>
            </div>
            </section>

            <section id='curriculum' className='curriculum1'>
            <div className='container'>
                <h2>{t('t226')}</h2>
                <div className='accordion'>
                    <button
                        className='accordion-header'
                    >
                        <span>Module 1: {t('t227')}</span>
                    </button>
                    <button
                        className='accordion-header'
                    >
                        <span>Module 2: {t('t228')}</span>
                    </button>
                    <button
                        className='accordion-header'
                    >
                        <span>Module 3: {t('t229')}</span>
                    </button>
                    <button
                        className='accordion-header'
                    >
                        <span>Module 4: {t('t230')}</span>
                    </button>
                </div>
            </div>
            </section>

            <section className='testimonials'>
            <div className='container'>
                <h2>{t('t231')}</h2>
                <div className='testimonial-grid'>
                {[
                    { name: 'Sara Amine', role: t('t239'), content: t('t232') },
                    { name: 'Abdelilah Talbi', role: t('t240'), content: t('t233') },
                    { name: 'Reda Abir', role: t('t241'), content: t('t234') },
                ].map((testimonial, index) => (
                    <div key={index} className='testimonial-card'>
                    <div className='stars'>
                        {[...Array(5)].map((_, i) => (
                        <Icon type='star' key={i} />
                        ))}
                    </div>
                    <p className='testimonial-content'>'{testimonial.content}'</p>
                    <p className='testimonial-author'>{testimonial.name}</p>
                    <p className='testimonial-role'>{testimonial.role}</p>
                    </div>
                ))}
                </div>
            </div>
            </section>
            <section id='faq' className='faq1'>
                <div className='container'>
                    <div className='title'>
                        <img src={i.faq} alt='faq icon' />
                        <h2>{t('t179')}</h2>
                    </div>
                    <div className='faq-list'>
                    {[
                        { question: t('t242'), answer: t('t235') },
                        { question: t('t243'), answer: t('t236') },
                        { question: t('t244'), answer: t('t237') },
                        { question: t('t245'), answer: t('t238') },
                        { question: t('t180'), answer: t('t248') },
                        { question: t('t249'), answer: t('t250') },
                    ].map((faq, index) => (
                        <div key={index} className='faq-item'>
                        <button
                            className='faq-question'
                            onClick={() => toggleFaq(index)}
                        >
                            <span>{faq.question}</span>
                            {activeFaq === index ? <Icon type='chevron-down' /> : <Icon type='chevron-right' />}
                        </button>
                        {activeFaq === index && (
                            <div className='faq-answer'>
                            <p>{faq.answer}</p>
                            </div>
                        )}
                        </div>
                    ))}
                    </div>
                </div>
            </section>
            <div className='startRightNow'>
                <h2>{t('t190')}</h2>
                <p>{t('t191')}</p>
                <MyButton title={t('t198')} icon='chevron-right' href={buyCourse} isMinimal='btn'/>
            </div>
            <footer className='footer'>
                <p>{t('t192')} : <a href='mailto:contact@alphalearner.ma'>contact@alphalearner.ma</a> | <a href='tel:212694128708'>+2126.94.12.87.08</a></p>
            </footer>
        </div>
    )
}

export function ExcelGraphsTCD() {
	const history = useNavigate()
	const [activeFaq, setActiveFaq] = useState(null)
    const toggleFaq1 = (index) => {
        setActiveFaq(activeFaq === index ? null : index)
    }
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {id} = useParams()
	const {loading, course} = useSelector(state=> state.course)
	const pivotTableModules = [t('t251'),t('t252'),t('t253'),t('t254'),t('t255'),t('t256')]
	const graphModules = [t('t257'),t('t258'),t('t259'),t('t260'),t('t256')]
	useEffect(() => {
		dispatch(getCourse(id))
		setTimeout(() => {
			window.scrollTo(0, 0)
		}, 10)
	},[dispatch])
	function buyCourse() {
		dispatch(getCourse(id))
        history('/buy-course/'+id)
    }
	if(loading || !course){
        return <Loading />
    }
	return (
		<div className='graphs-landing-page'>
			<div style={{height:80}} />
			<section className='graphs-hero'>
				<div className='graphs-container'>
					<h2>{t('t263')}</h2>
					<p className='sub'>{t('t264')}</p>
					<div className='graphs-cta-group'>
						<button className='graphs-cta-button graphs-primary' onClick={buyCourse}>{t('t299')}</button>
						<a className='graphs-cta-button graphs-secondary' href='#curriculum'>{t('t300')}</a>
					</div>
					<div className='graphs-features-highlight'>
						<h3 className='x'>{t('t265')}</h3>
						<div className='graphs-features-grid'>
							<div className='graphs-feature'>
								<i className='fas fa-table graphs-feature-icon'></i>
							<div>
								<h4>{t('t266')}</h4>
								<p>{t('t267')}</p>
							</div>
							</div>
							<div className='graphs-feature'>
								<i className='fas fa-chart-bar graphs-feature-icon'></i>
								<div>
									<h4>{t('t268')}</h4>
									<p>{t('t269')}</p>
								</div>
							</div>
							<div className='graphs-feature'>
								<i className='fas fa-chart-pie graphs-feature-icon'></i>
								<div>
									<h4>{t('t270')}</h4>
									<p>{t('t271')}</p>
								</div>
							</div>
							<div className='graphs-feature'>
								<i className='fas fa-chart-line graphs-feature-icon'></i>
								<div>
									<h4>{t('t272')}</h4>
									<p>{t('t273')}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='graphs-why'>
				<div className='content'>
					<h1>{t('t274')}</h1>
					<p>{t('t275')}</p>
				</div>
				<div className='thumbnail'>
					<img src={`/coursesFolder/${course.folderId}/thumb.jpg`} alt='Course thumbnail' />
				</div>
			</section>
			<div className='sectionTitle t'>
                <h1>{t('t293')}<span>{t('t294')}</span></h1>
                <div className='decoration'>
                    <div className='x'></div>
                </div>
            </div>
			<section id='pivot-tables' className='graphs-pivot-tables'>
			<div className='graphs-container'>
				<div className='graphs-content-grid'>
				<div className='graphs-image-container'>
					<img
					src={i.img14}
					alt='Pivot Table Example'
					/>
				</div>
				<div className='graphs-content'>
					<h3>{t('t276')}</h3>
					<ul className='graphs-benefits-list'>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t277')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t278')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t279')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t280')}</span>
					</li>
					</ul>
				</div>
				</div>
			</div>
			</section>
			<div className='sectionTitle t'>
                <h1>{t('t295')}<span>{t('t296')}</span></h1>
                <div className='decoration'>
                    <div className='x'></div>
                </div>
            </div>
			<section id='graphs' className='graphs-graphs'>
			<div className='graphs-container'>
				<div className='graphs-content-grid'>
				<div className='graphs-content'>
					<h3>{t('t282')}</h3>
					<ul className='graphs-benefits-list'>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t283')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t284')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t285')}</span>
					</li>
					<li>
						<i className='fas fa-check-circle graphs-icon'></i>
						<span>{t('t286')}</span>
					</li>
					</ul>
				</div>
				<div className='graphs-image-container'>
					<img
					src={i.img15}
					alt='Excel Graph Example'
					/>
				</div>
				</div>
			</div>
			</section>
			<div className='sectionTitle t'>
                <div className='anchor' id='curriculum'/>
                <h1>{t('t297')}<span>{t('t298')}</span></h1>
                <div className='decoration'>
                    <div className='x'></div>
                </div>
            </div>
			<section className='graphs-curriculum'>
			<div className='graphs-container'>
				<div className='graphs-curriculum-grid'>
				<div className='graphs-curriculum-column'>
					<h3>{t('t288')}</h3>
					<div className='graphs-module-list'>
					{pivotTableModules.map((module, index) => (
						<div key={index} className='graphs-module-item'>
						<div className='graphs-module-number'>{index + 1}</div>
						<span>{module}</span>
						</div>
					))}
					</div>
				</div>
				<div className='graphs-curriculum-column'>
					<h3>{t('t289')}</h3>
					<div className='graphs-module-list'>
					{graphModules.map((module, index) => (
						<div key={index} className='graphs-module-item'>
						<div className='graphs-module-number'>{index + 1}</div>
						<span>{module}</span>
						</div>
					))}
					</div>
				</div>
				</div>
			</div>
			</section>
			<section id='faq' className='faq1'>
                <div className='container'>
                    <div className='title'>
                        <img src={i.faq} alt='faq icon' />
                        <h2>{t('t179')}</h2>
                    </div>
                    <div className='faq-list'>
                    {[
                        { question: t('t242'), answer: t('t235') },
                        { question: t('t243'), answer: t('t236') },
                        { question: t('t244'), answer: t('t237') },
                        { question: t('t245'), answer: t('t238') },
                        { question: t('t180'), answer: t('t248_') },
                        { question: t('t249'), answer: t('t250') },
                    ].map((faq, index) => (
                        <div key={index} className='faq-item'>
                        <button
                            className='faq-question'
                            onClick={() => toggleFaq1(index)}
                        >
                            <span>{faq.question}</span>
                            {activeFaq === index ? <Icon type='chevron-down' /> : <Icon type='chevron-right' />}
                        </button>
                        {activeFaq === index && (
                            <div className='faq-answer'>
                            <p>{faq.answer}</p>
                            </div>
                        )}
                        </div>
                    ))}
                    </div>
                </div>
            </section>
			<section className='graphs-cta-banner'>
			<div className='graphs-container'>
				<h2>{t('t290')}</h2>
				<p>{t('t291')}</p>
				<button className='graphs-cta-button' onClick={buyCourse}>{t('t292')}</button>
			</div>
			</section>
		</div>
	)
}