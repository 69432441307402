import { useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import * as c from '../components'
import * as a from '../backend/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from 'primereact/contextmenu'
import { Switch } from '@chakra-ui/react'
function formatCurrency(value) {
    let stringValue = value.toString()
    let hasDecimalPart = stringValue.includes('.')
    let integerPart, decimalPart
    if (hasDecimalPart) {
        [integerPart, decimalPart] = stringValue.split('.')
    } else {
        integerPart = stringValue
        decimalPart = '00'
    }
    let formattedIntegerPart = parseInt(integerPart, 10).toLocaleString('de-DE')
    if (decimalPart.length === 1) {
        decimalPart += '0';
    }
    let formattedValue = formattedIntegerPart + ',' + decimalPart

    return `${formattedValue} DHS`;
}
export default function Admin() {
    const cm = useRef(null)
    const toast = useToast()
    const {t} = useTranslation()
    const location = useLocation()
    const { isOpen:isOpenCourses, onOpen:onOpenCourses, onClose:onCloseCourses } = useDisclosure()
    const { isOpen:isOpenBuyRequests, onOpen:onOpenBuyRequests, onClose:onCloseBuyRequests } = useDisclosure()
    const { isOpen:isOpenBuyRequest, onOpen:onOpenBuyRequest, onClose:onCloseBuyRequest } = useDisclosure()
    const dispatch = useDispatch()
    const history = useNavigate()
    const [name, setName] = useState('')
    const [req, setReq] = useState({course:{}})
    const [attCourses, setAttCourses] = useState([])
    const [err, setErr] = useState(null)
    const [selectedOption, setSelectedOption] = useState('')
    const [userId, setUserId] = useState('')
    const [buyRequests, setBuyRequests] = useState([])
    const [users_, setUsers_] = useState([{buyRequests:[]},{buyRequests:[]}])
    const [reqNumber, setReqNumber] = useState(0)
    const [income, setIncome] = useState(0)
    const {loading:loading1, users,error:error1} = useSelector(state => state.getUsers)
    const {loading:loading2, token,error:error2} = useSelector(state => state.login)
    const {loading:loading3, courses} = useSelector(state => state.courses)
    const {loading:loading4, requests} = useSelector(state => state.getUsersBuyRequests)
    const queryParams = new URLSearchParams(location.search)
    function filteredUsers(usersx){
        return usersx.filter(user => user.buyRequests.filter(request => request.state === 'pending').length > 0)
    }
    function transformDate(z) {
        const x = new Date(z)
        const d = String(x.getUTCDate()).padStart(2, '0')
        const m = String(x.getUTCMonth() + 1).padStart(2, '0')
        const y = x.getUTCFullYear()
        return `${d}/${m}/${y}`
    }
    function addCourse(){
        if(selectedOption){
            const isAttributed = attCourses.find(course => course.id === selectedOption)
            if(!isAttributed){
                setErr('')
                dispatch(a.addUserCourse(userId,selectedOption))
            }else{
                setErr('Course is already attributed')
            }
        }else{
            setErr('Choose a course')
        }
    }
    const items = [
        {
            label: t('t143'),
            icon: 'fa-solid fa-lines-leaning',
            key:'smtg1',
            command:()=>{onOpenCourses()}
        },
        {
            key:'smtg2',
            label: t('t144'),
            icon: 'fa-solid fa-cart-shopping',
            command:()=>{onOpenBuyRequests()}
        },
    ]
    function setVariables(variables){
        setName(variables.fullName)
        setAttCourses(variables.courses)
        setBuyRequests(variables.bR)
        setUserId(variables.id)
    }
    function onRightClick(e,variables){
        if (cm.current) {
            setVariables(variables)
            cm.current.show(e)
        }
    }
    useEffect(() => {
        dispatch(a.getCourses())
        dispatch(a.getUsers())
        dispatch(a.getUsersBuyRequests())
        if(error1 || error2){
            history('/?mssg=error2')
        }else{
            setUsers_(users)
            let x = 0
            let y = 0
            users.forEach(user=>{
                x += user.buyRequests.length
            })
            users.forEach(user =>{
                user.buyRequests.forEach(req =>{
                    if(req.state === 'approved'){
                        y += req.course.price
                    }
                })
            })
            setReqNumber(x+requests.length)
            setIncome(y)
        }
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
        if (queryParams.get('mssg') === 'done') {
            toast({
                title: t('t97'),
                status: 'success',
                isClosable: true,
                duration: 2000
            })
        } else if (queryParams.get('mssg') === 'error') {
            toast({
                title: t('t105'),
                status: 'error',
                isClosable: true,
                duration: 2000
            })
        }
        // eslint-disable-next-line
    }, [])
    if(loading1 || loading2 || loading3 || loading4 || !token || !users || !users_ || !requests){
        if(!token){history('/?mssg=error2')}
        return <c.Loading />
    }
    return <>
        <c.ModalCourses
            isOpen={isOpenCourses}
            onClose={onCloseCourses}
            addCourse={addCourse}
            name={name}
            courses={courses}
            attCourses={attCourses}
            err={err}
            userId={userId}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
        />
        <c.ModalPurchases
            isOpen={isOpenBuyRequests}
            onClose={onCloseBuyRequests}
            name={name}
            buyRequests={buyRequests}
        />
        <c.ModalPurchase
            isOpen={isOpenBuyRequest}
            onClose={onCloseBuyRequest}
            req={req}
        />
        <div style={{ height: 80 }} />
        <div className='topBanner3'>
            <h1>{t('t128')}</h1>
            <p>{t('t129')} {token.slice(-10)}</p>
            <c.MyButton title={t('t130')} icon='download' isMinimal='btn' href={()=>{dispatch(a.downloadExcel())}} type={3} />
            <div className='onlyPurchases'>
                <Switch onChange={(e)=>{
                    if(!e.target.checked){
                        setUsers_(users)
                    }else{
                        setUsers_(filteredUsers(users_))
                    }
                }} />
                <h6>{t('t145')}</h6>
            </div>
        </div>
        <div className='statistics'>
            <c.StatCard
                bg='#00BFAF'
                icon='dollar-sign'
                title='Income'
                value={formatCurrency(income)}
            />
            <c.StatCard
                bg='#0CAB51'
                icon='user'
                title='Users'
                value={users_.length}
            />
            <c.StatCard
                bg='#AB740F'
                icon='bell'
                title='Requests Number'
                value={reqNumber}
            />
        </div>
        <div className='usersTable'>
            <div className='xrow n'>
                <p></p>
                <p>{t('t12')}</p>
                <p>{t('t13')}</p>
                <p>{t('t48')}</p>
                <p>{t('t133')}</p>
            </div>
            {users_.map(user => <div 
                key={user._id}
                className={
                    'xrow '+
                    (user.buyRequests.filter(request => request.state === 'pending').length > 0
                     ? 'hasBuyRequest' : '')
                } 
                onContextMenu={(e) => onRightClick(
                    e,
                    {
                        id:user._id,
                        fullName:user.fullName,
                        courses:user.courses,
                        bR:user.buyRequests
                    }
                )}
                >
                <div><img src={'/profilePhotos/'+user.profilePath} alt='' /></div>
                <p>{user.fullName}</p>
                <p>{user.email}</p>
                <p>{user.phoneNumber}</p>
                <p>{transformDate(user.createdAt)}</p>
            </div>)}
            {requests.map(req => <div className='xrow y' onClick={()=>{
                setReq(req)
                onOpenBuyRequest()
            }}>
                <div><h6>REQ</h6></div>
                <p>{req.fullName}</p>
                <p>{req.email}</p>
                <p>{req.phoneNumber}</p>
                <p>{transformDate(req.createdAt)}</p>
            </div>)}
            <ContextMenu model={items} ref={cm} />

        </div>
    </>
}