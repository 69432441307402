import { useState } from 'react'
import { Icon } from '.'
import { Field } from 'formik';
export default function Input({ type, icon, inputDescription, name }) {
    const [isVisible, setIsVisible] = useState(false);
    if (type === 'password') {
        return <div className='theInput'>
            <div className='iconLabel x'>
                <Icon type='lock' />
            </div>
            <Field className='entryInput' type={!isVisible ? 'password' : 'text'} placeholder={inputDescription} name={name} />
            <div onClick={() => { setIsVisible(!isVisible) }} className='eyeToggler'>
                <Icon type={!isVisible ? 'eye-slash' : 'eye'} />
            </div>
        </div>
    }
    return <div className='theInput x'>
        <div className='iconLabel'>
            <Icon type={icon} />
        </div>
        <Field className='entryInput' placeholder={inputDescription} name={name} />
    </div>
}
