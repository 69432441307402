import { useEffect, useState, useRef } from 'react'
import { Loading, VideoList,VideoPlayer } from '../components'
import * as a from '../backend/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkForTitleTranslation } from '.'
import { useTranslation } from 'react-i18next'

export default function Course() {
    const {t} = useTranslation()
    function formatDuration(sec) {
        let min = Math.floor(sec / 60);
        let rsec = sec % 60;
        if(rsec < 10){rsec = '0' + rsec}
        if(min < 10){min = '0' + min}
        return min + ':' + rsec;
    }
    const videoRef = useRef(null)
    const [videoUrl, setVideoUrl] = useState()
    const [videoTitle, setVideoTitle] = useState('')
    const [videoId, setVideoId] = useState('')
    const [videoDuration, setVideoDuration] = useState('0:00')
    const [focusState, setFocusState] = useState(1)
    const [tempDone, setTempDone] = useState([])
    const dispatch = useDispatch()
    const history = useNavigate()
    const {loading:loading1, videos, error:error1} = useSelector(state => state.courseVideos)
    const {loading:loading2, course, error:error2} = useSelector(state => state.course)
    const {loading:loading3, userInfo} = useSelector(state => state.userDetails)
    const {loading:loading4, courseProgress, error:error3} = useSelector(state => state.getCourseProgress)
    const { id } = useParams()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
        if(userInfo){
            if(userInfo.attributedCourses.includes(id)){
                dispatch(a.getCourse(id))
                dispatch(a.getCourseVideos(id,setVideoTitle,setVideoDuration,setVideoId,formatDuration,setVideoUrl,0))
                dispatch(a.getCourseProgress(id,userInfo._id))
            }else{
                history('/?mssg=error2')
            }
        }else{
            history('/?mssg=error2')
        }
        // eslint-disable-next-line
    }, []);
    if (loading1 || loading2 || loading3 || loading4 || !course || !videos || !courseProgress) {
        return <Loading />
    }else if (error1 || error2 || error3){
        history('/?mssg=error')
    }else{
    return (
        <>
            <div style={{ height: 80 }} />
            <div className='coursePlayer'>
                <div className='playlist'>
                {videos.map(video => <VideoList
                        done={courseProgress.find(vid => vid.video === video._id && vid.done) || tempDone.includes(video._id) ? 'done' : undefined}
                        key={video._id}
                        title={video.title}
                        duration={formatDuration(video.duration)}
                        focus={focusState === video.videoNumber ? 'focus ' : undefined}
                        click={()=>{
                            setVideoId(video._id)
                            setVideoUrl(video.filename)
                            setFocusState(video.videoNumber)
                            setVideoDuration(formatDuration(video.duration))
                            setVideoTitle(video.title)
                        }}
                    />
                )}
                </div>
                <div className='videoPlayer'>
                    <VideoPlayer
                        videoRef={videoRef}
                        videoX={`/coursesFolder/${course.folderId}/${videoUrl}`}
                        title={videoTitle}
                        duration={videoDuration}
                        next={()=>{
                            if(focusState < videos.length){
                            setVideoUrl(videos[focusState].filename)
                            setFocusState(focusState+1)
                            setVideoDuration(formatDuration(videos[focusState].duration))
                            setVideoId(videos[focusState]._id)
                            setVideoTitle(videos[focusState].title)}
                        }}
                        previous={()=>{
                            if(focusState > 1){
                            setVideoUrl([focusState-2].filename)
                            setFocusState(focusState-1)
                            setVideoDuration(formatDuration(videos[focusState-2].duration))
                            setVideoId(videos[focusState-2]._id)
                            setVideoTitle(videos[focusState-2].title)}
                        }}
                        userMetMin={(durationWatched)=>{
                            a.videoDone({
                                courseId:course._id,
                                userId:userInfo._id,
                                videoId,
                                durationWatched,
                            })
                            setTempDone(!tempDone.includes(videoId) ? [...tempDone,videoId] : tempDone)
                        }}
                    />
                </div>
            </div>
            <h1 className='courseTitle'>{checkForTitleTranslation(course.title,t)}</h1>
            <div className='videoDescription'>
                <p>{course.description}</p>
            </div>
        </>
    )}
}
