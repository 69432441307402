import { useState } from 'react'

export default function RatingComment({rating,setRating}){
  	const [hover, setHover] = useState(0);
  	return (
		<div className="ratingOf">
		{[...Array(5)].map((_, index) => {
			const starValue = index + 1;
			return (
				<i
					key={index}
					className={`${
					starValue <= (hover || rating) ? 'fa-solid' : 'fa-regular'
					} fa-star`}
					onMouseEnter={() => setHover(starValue)}
					onMouseLeave={() => setHover(0)}
					onClick={() => setRating(starValue)}
				/>
			)
		})}
		</div>
  )
}