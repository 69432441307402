import { useState, useRef } from 'react'
import { Icon } from '.'

export default function VideoPlayer ({ videoX, title, duration, next, previous,videoRef,userMetMin }){
	const [currentTimeState, setCurrentTimeState] = useState('0:00')
	const [speedBtnState, setSpeedBtnState] = useState('1x')
	const [isScrubbing, setIsScrubbing] = useState(false)
	const [wasPaused, setWasPaused] = useState(false)
	const [progressPosition, setProgressPosition] = useState(0)
	const [volume, setVolume] = useState('1')
	const [speed, setSpeed] = useState(1)
	const [videoContainerState, setVideoContainerState] = useState(false)
	const [seconds, setSeconds] = useState([])
	const timelineContainerRef = useRef(null)
	const videoContainerRef = useRef(null)
	function reduceStringLength(str){
		if(str){

			if(str.length < 25){return str}
			return str.slice(0,25)+'...'
		}
		return ''
    }
	function changePlaybackSpeed() {
		if (speed > 1.75) {
			setSpeed(0.25)
		}else{
			setSpeed(speed + 0.25)
		}
		videoRef.current.playbackRate = speed
		setSpeedBtnState(`${speed}x`)
	}
	function formatDuration(time) {
		const seconds = Math.floor(time % 60)
		const minutes = Math.floor(time / 60) % 60
		const hours = Math.floor(time / 3600)
		if (hours === 0) {
			return `${minutes}:${leadingZeroFormatter.format(seconds)}`
		} else {
			return `${hours}:${leadingZeroFormatter.format(
			minutes
			)}:${leadingZeroFormatter.format(seconds)}`
		}
	}
	// function skip(duration) {
	// 	videoRef.current.currentTime += duration
	// }
	function toggleMute() {
		videoRef.current.muted = !videoRef.current.muted
	}
	function toggleFullScreenMode() {
		if (document.fullscreenElement == null) {
			videoContainerRef.current.requestFullscreen()
		} else {
			document.exitFullscreen()
		}
	}
	const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
		minimumIntegerDigits: 2,
	})
	function handleTimelineUpdate (e){
		if (timelineContainerRef.current) {
			const rect = timelineContainerRef.current.getBoundingClientRect()
			const percent =
			Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width
			if (
			isScrubbing &&
			document
				.querySelector('.video-container')
				.classList.contains('scrubbing')
			) {
			e.preventDefault()
			setProgressPosition(percent)
			}
		}
	}
	function togglePlay(){ 
		videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
	}
	function toggleScrubbing(e){
		if (timelineContainerRef.current) {
			const rect = timelineContainerRef.current.getBoundingClientRect()
			const percent =
			Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width
			const isScrubbingState = (e.buttons & 1) === 1
			setIsScrubbing(isScrubbingState)
			const videoContainer = document.querySelector('.video-container')
			const video = document.querySelector('video')
			if (videoContainer) {
				videoContainer.classList.toggle('scrubbing', isScrubbingState)
				setVideoContainerState(videoContainer.classList.contains('scrubbing'))
			}
			if (video) {
				if (isScrubbingState && videoContainer.classList.contains('scrubbing')) {
					setWasPaused(videoRef.current.paused)
					videoRef.current.pause()
				} else {
					videoRef.current.currentTime = percent * videoRef.current.duration
					if (!wasPaused) videoRef.current.play()
				}
			}
		handleTimelineUpdate(e)
		}
	}
	function volumeChange(){
		setVolume(videoRef.current.volume)
		let volumeLevel
		if (videoRef.current.muted || videoRef.current.volume === 0) {
			setVolume(0)
			volumeLevel = 'muted'
		} else if (videoRef.current.volume >= 0.5) {
			volumeLevel = 'high'
		} else {
			volumeLevel = 'low'
		}
		videoContainerRef.current.dataset.volumeLevel = volumeLevel
	}
	// videoContainerRef.current.addEventListener('keydown', (e) => {
	// 	const tagName = document.activeElement.tagName.toLowerCase()
	// 	if (tagName === 'input') return
	// 	switch (e.key.toLowerCase()) {
	// 		case ' ':
	// 			togglePlay()
	// 			break
	// 		case 'k':
	// 			break
	// 		case 'f':
	// 			toggleFullScreenMode()
	// 			break
	// 		case 'm':
	// 			toggleMute()
	// 			break
	// 		case 'arrowleft':
	// 		case 'j':
	// 			skip(-5)
	// 			break
	// 		case 'arrowright':
	// 		case 'l':
	// 			skip(5)
	// 			break
	// 	}
	// })
	document.addEventListener('fullscreenchange', () => {
		const videoContainer = document.querySelector('.video-container')
		videoContainer.classList.toggle('full-screen', document.fullscreenElement)
	})
	function userMetCall(){
		const watchedTime = seconds.length
		const videoDuration = videoRef.current.duration
		if(watchedTime >= videoDuration * 0.9){
			userMetMin(watchedTime)
		}
	}
	function updateSeconds(){
		const second = Math.floor(videoRef.current.currentTime)
		if(!seconds.includes(second)){setSeconds([...seconds,second])}
	}
	function handleEnd(){
		userMetCall()
		setSeconds([])
		next()
	}
	const fullScreenBtn = <button className='full-screen-btn' onClick={toggleFullScreenMode}>
		<Icon type='expand open'/>
		<Icon type='compress close'/>
	</button>
	const muteBtn = <button className='mute-btn' onClick={toggleMute}>
		<Icon type='volume-high volume-high-icon'/>
		<Icon type='volume-low volume-low-icon'/>
		<Icon type='volume-xmark volume-muted-icon'/>
	</button>
	const speedBtn = <button className='speed-btn wide-btn' onClick={changePlaybackSpeed}>{speedBtnState}</button>
	const currentTimeElem = <div className='current-time'>{currentTimeState}</div>
	const volumeSlider = <input
		className='volume-slider'
		type='range'
		min='0'
		max='1'
		step='any'
		value={volume}
		onInput={(e) => {
			videoRef.current.volume = e.target.value
			videoRef.current.muted = e.target.value === 0
		}}
	/>
	const timelineContainer = <div
		className='timeline-container'
		onMouseMove={handleTimelineUpdate}
		onMouseDown={toggleScrubbing}
		ref={timelineContainerRef}
		style={{ '--progress-position': progressPosition }}
	>
		<div className='timeline'>
			<div className='thumb-indicator'/>
		</div>
	</div>

	const playPauseBtn = <button className='play-pause-btn' onClick={togglePlay}>
		<Icon type='play play-icon'/>
		<Icon type='pause pause-icon'/>
	</button>
	const video = <video
		id='myVideo'
		onSeeked={()=>{
			updateSeconds()
			userMetCall()
		}}
		onEnded={handleEnd}
		src={videoX}
		onClick={() => {togglePlay(this)}}
		ref={videoRef}
		onTimeUpdate={() => {
			setCurrentTimeState(formatDuration(videoRef.current.currentTime))
			const percent = videoRef.current.currentTime / videoRef.current.duration
			setProgressPosition(percent)
			updateSeconds()
			userMetCall()
		}}
		onPause={() => {videoContainerRef.current.classList.add('paused')}}
		onPlay={() => {videoContainerRef.current.classList.remove('paused')}}
		onVolumeChange={volumeChange}
	>
		<track />
	</video>
	const videoContainer = <div
		onContextMenu={e => e.preventDefault()}
		className='video-container paused'
		data-volume-level='high'
		ref={videoContainerRef}
		onMouseUp={(e) => {if (videoContainerState) toggleScrubbing(e)}}
		onMouseMove={(e) => {if (videoContainerState) handleTimelineUpdate(e)}}
	>
		<div className='videoPlayerTitle'>
			<h1>{reduceStringLength(title)}</h1>
		</div>
		<div className='video-controls-container'>
			{timelineContainer}
			<div className='controls'>
				{playPauseBtn}
				<div className='volume-container'>
					{muteBtn}
					{volumeSlider}
				</div>
				<div className='duration-container'>
					{currentTimeElem}/{duration}
				</div>
				<button className='captions-btn' onClick={previous}>
					<Icon type='backward-step'/>
				</button>
				<button className='captions-btn' onClick={next}>
					<Icon type='forward-step'/>
				</button>
				{speedBtn}
				{fullScreenBtn}
			</div>
		</div>
		{video}
	</div>

	return videoContainer
}
